export default {
  Эпидемиология: {
      core: "Application of Epidemiological Principles (OIE 2.1) (includes 3.3)",
      definition:
        "Эпидемиология – наука, изучающая факторы, определяющие возникновение, частоту и распространение заболеваний или иных связанных со здоровьем событий и их причин в определенной популяции в целях профилактики и контроля их развития и распространения и разработки программ по предотвращению развития и распространения.",
      importance:
        "эпидемиология служит базой для логического обоснования мероприятий, проводимых в рамках реагирования на чрезвычайные ситуации, связанные со здоровьем животных, в интересах ветеринарного здравоохранения и профилактической медицины.",
      differentLevels: [
        "Общая осведомленность",
        "Новичок",
        "Компетентный",
        "Профессионал",
        "Эксперт"
      ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "объяснять общие принципы описательной эпидемиологии, их применение в профилактике болезней и борьбе с болезнями, обращаться к необходимой информации (стандартные операционные процедуры - СОП) и использовать ее, объяснять, как проводить эпидемиологическое расследование в случае возникновения регистрируемого заболевания",
          role: "Наем на первый день в ВЕТЕРИНАРНУЮ СЛУЖБУ",
          getPrepared: "Основные принципы",
          PCP: "",
          PVS: "",
          EuFMDCourses: "",
          ISAVET: "Полевое расследование / Меры реагирования 7.1",
          BTSF: "",
          USAID: "",
        },
        {
          name: "Новичок",
          behaviour:
            "применять знания об эпидемиологических характеристиках инфекционных заболеваний, ассистировать в проведении эпидемиологического расследования (собирать эпидемиологические данные по установленной форме), описать эпидемиологические подходы, необходимые в случаях чрезвычайных ситуаций, вызванных заболеваниями животных, и воздействия выбранных эпидемиологических подходов (на индивидуальном уровне, на уровне ветеринарной службы, на уровне популяции).",
          role: "Наем частнопрактикующего врача в ВЕТЕРИНАРНУЮ СЛУЖБУ / ветеринарный фельдшер",
          getPrepared: "Оповещение",
          PCP: "1,2,3",
          PVS: "",
          EuFMDCourses: "КУРС ПОДГОТОВКИ К ЧРЕЗВЫЧАЙНЫМ СИТУАЦИЯМ, СВЯЗАННЫМ С ЯЩУРОМ, УЧЕБНЫЙ КУРС ПО РАССЛЕДОВАНИЯМ СЛУЧАЕВ ЗАБОЛЕВАНИЯ ЯЩУРОМ, АФРИКАНСКАЯ ЧУМА СВИНЕЙ, НОДУЛЯРНЫЙ ДЕРМАТИТ КРУПНОГО РОГАТОГО СКОТА",
          ISAVET:
            "Эпидемиология, эпиднадзор 2.1, 2.2 Полевое расследование / Меры реагирования 7.1 – 7.4",
          BTSF: "",
          USAID: "Первая линия 2.8b",
        },
        {
          name: "Компетентный",
          behaviour:
            "проводить эпидемиологическое расследование в предполагаемом/подтвержденном зараженном помещении (включает отслеживание входящих и исходящих животных, определение случаев заболевания), определять пути передачи заболевания в зараженном помещении, включая использование знаний в области полевой эпидемиологии для сбора данных, оценивать данные расследования заболевания (качество и комплектность)",
          role: "Ветеринарный врач государственной ветеринарной службы",
          getPrepared: "Оповещение Чрезвычайная ситуация",
          PCP: "1,2,3",
          PVS: "II-2 3",
          EuFMDCourses: "КУРС ПОДГОТОВКИ К ЧРЕЗВЫЧАЙНМ СИТУАЦИЯМ, СВЯЗАННЫМ С ЯЩУРОМ, УЧЕБНЫЙ КУРС ПО РАССЛЕДОВАНИЮ СЛУЧАЕВ ЗАБОЛЕВАНИЯ ЯЩУРОМ, ОБУЧЕНИЕ В РЕЖИМЕ РЕАЛЬНОГО ВРЕМЕНИ, АФРИКАНСКАЯ ЧУМА СВИНЕЙ, НОДУЛЯРНЫЙ ДЕРМАТИТ КРУПНОГО РОГАТОГО СКОТА",
          ISAVET: "Полевое расследование / Меры реагирования 7.4-7.5",
          BTSF: "",
          USAID: "Первая линия 1.3, Первая линия 2.8d-h",
        },
        {
          name: "Профессионал",
          behaviour:
            "применять современные принципы эпидемиологии для определения источника вспышки заболевания, проверять и анализировать данные, полученные на местах, использовать эпидемиологические модели, проводить анализ риска, давать рекомендации по необходимым мероприятиям, прогнозировать воздействие (преимущества, обязательства, воздействие на заинтересованные стороны), разрабатывать эпидемиологические опросные листы, разрабатывать инструменты для сбора данных и принятия решений на основе собранных данных (т. е. разрабатывать стратегию эпидемиологического надзора).",
          role: "Ветеринар - советник/эпидемиолог",
          getPrepared: "Оповещение Чрезвычайная ситуация",
          PCP: "",
          PVS: "",
          EuFMDCourses:
            "АНАЛИЗ РИСКА НА ВСЕХ ЭТАПАХ ПРОИЗВОДСТВЕННО-СБЫТОВЫХ ЦЕПОЧЕК, Оценка социально-экономического воздействия (экономический аспект), ПОСТВАКЦИНАЛЬНЫЙ МОНИТОРИНГ, ВАКЦИНЫ, ВАКЦИНАЦИЯ И ПОСТВАКЦИНАЛЬНЫЙ МОНИТОРИНГ, Семинары по построению эпидемиологических моделей и другим темам",
          ISAVET: "Эпидемиологический надзор 3.1-3.3 4.3-4.4 5.1-5.2",
          BTSF: "Болезни животных: готовность к чрезвычайным ситуациям / раннее предупреждение / планирование действий на случай чрезвычайных ситуаций / борьба с болезнями",
          USAID:
            "Первая линия 1.4c-f, Первая линия 1.5b, Среднее звено 1.2, Среднее звено 2.8av, 2.8bi-iii, vi 2.8ci-vi",
        },
        {
          name: "Эксперт",
          behaviour:
            "определять выработку стратегических планов реагирования на чрезвычайные ситуации, включая секторы ветеринарии, здравоохранения и охраны окружающей среды (подход 'Единое здоровье'), прогнозировать вероятность достижения поставленных целей, предвидеть последствия (преимущества, обязательства, воздействие на заинтересованные стороны),отвечать за перспективное планирование во время вспышки и стратегию восстановления.",
          role: "Специалист по разработке политики",
          getPrepared: "Основные принципы",
          PCP: "",
          PVS: "II-2 4, 5",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "",
          USAID: "Первая линия 3.11b, Среднее звено 3.12a",
        },
      ],
      competency: "Эпидемиология",
    },
  
    "Трансграничные болезни": {
      core: "рансграничные болезни животных (ТБЖ) (ВООЗЖ 2.3) Составление профиля болезни",
      definition:
        "ТБЖ – высококонтагиозные заболевания, способные стремительно распространяться, независимо от национальных границ. Составление профиля болезни включает описание клинических симптомов, этиологии, патогенеза, клинического течения, потенциала передачи и эпидемиологии болезни. Контроль и профилактика входят в компетенцию контроля и профилактики заболеваний. ТБЖ включают африканскую чуму свиней, высокопатогенный грипп птиц, контагиозную плевропневмонию крупного рогатого скота, геморрагическую септицемию, грипп типа A, чуму крупного рогатого скота, лихорадку долины Рифт, вызываемый коронавирусом ближневосточный респираторный синдром и ящур.",
      importance:
        "Почему это важно: не все возбудители ТБЖ являются зоонозными патогенами, но независимо от зоонозного потенциала ТБЖ высококонтагиозная природа этих заболеваний представляет постоянную угрозу для источников средств к существованию животноводов. Вспышки ТБЖ могут оказать значительное пагубное воздействие на национальную экономику и отразиться на глобальной экономике, глобальной торговле и глобальной системе здравоохранения.",
        differentLevels: [
          "Общая осведомленность",
          "Новичок",
          "Компетентный",
          "Профессионал"
        ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "перечислить и описать распространенные ТБЖ (поражаемые виды, клинические симптомы, дифференциальные диагнозы, клиническое течение, основные пути передачи, возможные пути заноса в страну), перечислить инструменты, используемые для профилактики и борьбы с ТБЖ (включая вакцинацию), находить актуальную информацию по распространению.",
          role: "N/A",
          getPrepared: "Основные принципы",
          PCP: 0,
          PVS: "II-2A 3 II-2B 3",
          EuFMDCourses: "КУРС ПОДГОТОВКИ К ЧРЕЗВЫЧАЙНЫМ СИТУАЦИЯМ, СВЯЗАННЫМ С ЯЩУРОМ, УЧЕБНЫЙ КУРС ПО РАССЛЕДОВАНИЯМ СЛУЧАЕВ ЗАБОЛЕВАНИЯ ЯЩУРОМ, ОБУЧЕНИЕ В РЕЖИМЕ РЕАЛЬНОГО ВРЕМЕНИ, АФРИКАНСКАЯ ЧУМА СВИНЕЙ, НОДУЛЯРНЫЙ ДЕРМАТИТ КРУПНОГО РОГАТОГО СКОТА",
          ISAVET: "",
          BTSF: "Болезни животных: готовность к чрезвычайным ситуациям / раннее предупреждение / планирование действий на случай чрезвычайных ситуаций / борьба с болезнями",
          USAID: "",
        },
        {
          name: "Новичок",
          behaviour:
            "уметь перечислить ТБЖ, подлежащие нотификации, знать национальную систему отчетности, уметь описывать патоген, патологические изменения, патофизиологию, возможные пути передачи (включая переносчиков) и методы отбора образцов, уметь описать обстоятельства, требующие применения СРЕДСТВ ИНДИВИДУАЛЬНОЙ ЗАЩИТЫ, уметь перечислить диагностические инструменты, использующиеся для профилактики болезней и борьбы с ними, обладать навыками обследования живых животных.",
          role: "Наем частнопрактикующего врача в ВЕТЕРИНАРНУЮ СЛУЖБУ, Day 1 hire into VS, Veterinary Paraprofessional",
          getPrepared: "Оповещение",
          PCP: "1,2,3",
          PVS: "II-2A 3 II-2B 3",
          EuFMDCourses: "КУРС ПОДГОТОВКИ К ЧРЕЗВЫЧАЙНЫМ СИТУАЦИЯМ, СВЯЗАННЫМ С ЯЩУРОМ, УЧЕБНЫЙ КУРС ПО РАССЛЕДОВАНИЯМ СЛУЧАЕВ ЗАБОЛЕВАНИЯ ЯЩУРОМ, АФРИКАНСКАЯ ЧУМА СВИНЕЙ, НОДУЛЯРНЫЙ ДЕРМАТИТ КРУПНОГО РОГАТОГО СКОТА",
          ISAVET: "Эпидемиологический надзор 2.1, 2.3",
          BTSF: "",
          USAID: "",
        },
        {
          name: "Компетентный",
          behaviour:
            "проводить клиническое обследование стада и отдельных животных с подозрением на ТБЖ (включая вскрытие и описание видоспецифических признаков), определять время образования патологических изменений (в случае необходимости), выбирать животных для отбора образцов, обосновывать применение диагностических инструментов и терапевтических средств, определять вероятность трансграничного происхождения болезни (достоверная диагностика подозрительных случаев), отправлять отчеты в соответствии с существующими требованиями.",
          role: "Ветеринарный врач государственной ветеринарной службы",
          getPrepared: "Оповещение",
          PCP: "",
          PVS: "II-2A 4",
          EuFMDCourses: "КУРС ПОДГОТОВКИ К ЧРЕЗВЫЧАЙНЫМ СИТУАЦИЯМ, СВЯЗАННЫМ С ЯЩУРОМ, УЧЕБНЫЙ КУРС ПО РАССЛЕДОВАНИЯМ СЛУЧАЕВ ЗАБОЛЕВАНИЯ ЯЩУРОМ, ВАКЦИНЫ, ВАКЦИНАЦИЯ И ПОСТВАКЦИНАЛЬНЫЙ МОНИТОРИНГ, ПОСТВАКЦИНАЛЬНЫЙ МОНИТОРИНГ, ОБУЧЕНИЕ В РЕЖИМЕ РЕАЛЬНОГО ВРЕМЕНИ, АФРИКАНСКАЯ ЧУМА СВИНЕЙ, НОДУЛЯРНЫЙ ДЕРМАТИТ КРУПНОГО РОГАТОГО СКОТА",
          ISAVET: "Профессиональная подготовка в полевых условиях",
          BTSF: "",
          USAID: "Первая линия 1.3, Первая линия 2.8a, Первая линия 2.8b",
        },
        {
          name: "Профессионал",
          behaviour:
            "консультировать и обучать персонал более низкого уровня, адаптировать диагностические и терапевтические инструменты на основе новых научных данных, давать рекомендации / принимать решения в тех случаях, когда клинические признаки неясны или неоднозначны, наладить информационную работу с сетями за пределами его организации, предоставлять наиболее актуальную информацию по профилю болезни, включая результаты последних исследований, характеризовать бремя ТБЖ на национальном уровне (заболеваемость, смертность, аборты, снижение производства и т. д.), дать оценку социально-экономического и экономического воздействия ТБЖ на национальном уровне.",
          role: "Специалист по разработке политики  Ветеринарный советник",
          getPrepared: "Основные принципы",
          PCP: "",
          PVS: "II-2A 4",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "",
          USAID: "",
        },
      ],
      competency: "Transboundary Animal Diseases",
    },
    "Disease Prevention and Control Programs General": {
      definition:
        "Disease prevention and control programmes, include surveillance, movement controls, vaccination, treatment, containment zones, biosecurity, isolation, culling and stamping out. These may be joint programs (public-private partnerships). Some control programs are the result of application of other competencies ie a surveillance program is the result of sampling, clinical signs and epidemiology. Others are their own competency.",
      importance:
        "prevention and control programs are key to managing TADs, emerging re-emerging diseases protecting animal welfare, trade, industry and individual producer viability, food security",
      competency: "Disease Prevention and Control Programs General",
      differentLevels: [
        "Общая осведомленность",
        "Новичок",
        "Компетентный",
        "Профессионал",
        "Эксперт"
      ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "explain principles of disease control (role/characteristic of surveillance), describe established programs and their role in them for the prevention and control of TADs, explain the concept of “early detection system, list which diseases of animals require compulsory notification, find up-to-date and reliable information regarding specific disease, prevention and control measures, including rapid response mechanisms, describe the One Health approach",
          role: "Day 1 hire into VS",
          getPrepared: "Foundations",
          PCP: "3",
          PVS: "I-5 3",
          EuFMDCourses: "PPP",
          ISAVET: "Field Investigation/ Response 7.1",
          BTSF: "Epi surveillance 1.1-1.3, Disease Prevention/ Control 13.1-13.2",
          USAID:
            "Frontline 1.1, Frontline 1.4a, Frontline 2.10b-c, Intermediate 1.1a",
        },
        {
          name: "Новичок",
          behaviour:
            "participates in established disease control plans such as surveillance, vaccination campaigns, post vaccine monitoring, test-and-cull/treat programmes",
          role: "Private practitioner hirer into VS Veterinary Paraprofessional",
          getPrepared: "Alert Emergency",
          PCP: "3",
          PVS: "I-5 3",
          EuFMDCourses: "FEPC FITC PPP VVPV PVM RRT Wildlife surv ASF LSD",
          ISAVET: "Epi surveillance 4.1",
          BTSF: "",
          USAID: "Frontline 2.10a",
        },
        {
          name: "Компетентный",
          behaviour:
            "leads (on site or virtually) implementation of disease control plans, summarize and report surveillance data, act if incident exceed thresholds, oversees local level public-private partnerships (which could include leading a team, accreditation and/or audit), explain policies and outcomes to affected parties, coordinate with other parties and stakeholders for efficient implementation of the program, recommend basic outbreak prevention and control measures",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Alert Emergency",
          PCP: "3",
          PVS: "I-5 3",
          EuFMDCourses: "FEPC FITC PPP VVPV PVM RRT Wildlife surv ASF LSD",
          ISAVET:
            "Epi surveillance 2.0, 4.2, 4.5, Field Investigation /Response 7.6",
          BTSF: "Animal disease Preparedness/ early warning/ contingency planning/disease control",
          USAID: "Frontline 1.4b Frontline 1.5a, Frontline 2.8.i",
        },
        {
          name: "Профессионал",
          behaviour:
            "provide guidance to field staff on implementation and interpretation of programs in unusual/complex situations, - describe why the policy was designed the way it was, predict the impact of changes in implementation of program and make recommendations, -improve programs based on experience and applies lessons learned more broadly across stakeholders, develop public private partnerships at local level",
          role: "Veterinary advisor",
          getPrepared: "Alert Emergency",
          PCP: 3,
          PVS: "",
          EuFMDCourses: "VVPV Risk based Surv PVM",
          ISAVET: "Epi surveillance 5.3",
          BTSF: "",
          USAID: "Intermediate 1.1b-d",
        },
        {
          name: "Эксперт",
          behaviour:
            "write/ modify programs based on epidemiology, risk analysis, monitoring of results, global strategies for control of a disease including animal, human and environmental sectors (one health), evaluate cost effectiveness of program, access latest science, access and apply governing body (ie OIE) best practices/recommendations, predict the likelihood of program achieving goals, develop public private partnerships at national level",
          role: "Policy developer",
          getPrepared: "Foundations, Reconstruction",
          PCP: "3",
          PVS: "I-5, 4,5",
          EuFMDCourses: "VVPV PVM Risk based Surv",
          ISAVET: "",
          BTSF: "",
          USAID: "Frontline 3.11b Intermediate 2.8di, Intermediate 3.12a",
        },
      ],
    },
    Biosecurity: {
      definition:
        "A set of management and physical measures designed to reduce the risk of introduction, establishment and spread of animal diseases, infections, or infestations to, from and within an animal population. Includes the concepts of bio exclusion, bio containment and bio management, in reference to a farm and personal biosecurity. Relates to the ability to move and work between premises without being a vector for disease, during day to work and outbreak situations",
      importance:
        "Producers place significant trust in the VS especially during outbreaks situations. Biosecurity is critical to the protection of uninfected farms and enabling permitted movement.",
      competency: "Biosecurity",
      differentLevels: [
        "Общая осведомленность",
        "Новичок",
        "Компетентный",
        "Профессионал",
        "Эксперт"
      ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "define biosecurity, explain on farm biosecurity practices, recognize the principles of biosecurity, recognize environments needing biosecurity, describe the impacts of a breach in biosecurity, mitigation measures",
          role: "N/A",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "II-2 A 3 II-2 B 3",
          EuFMDCourses: "FEPC/FIT, C, RRT, Asf",
          ISAVET: "",
          BTSF: "",
          USAID: "",
        },
        {
          name: "Новичок",
          behaviour:
            "apply biosecurity principles (where to park and keeping a clean zone in vehicle) , equipment (includes care and maintenance) and processes needed to move safely between premises during regular/low disease transfer, recognize a biosecurity breach, - assess risk and insitute mitigating measures in low risk situations, explain how to enter and leave a premise suspected of FAST disease , safely don and doff PPE, member of a biosecurity team during an outbreak",
          role: "Private practitioner hirer into VS Veterinary Paraprofessional",
          getPrepared: "Alert",
          PCP: "1,2,3 vets, 2,3 PP",
          PVS: "II-2 A 3 II-2 B 3",
          EuFMDCourses: "FEPC RRT ASF",
          ISAVET: "Field Investigation/Response 7.1",
          BTSF: "",
          USAID: "Frontline 2.7 Intermediate 3.12b",
        },
        {
          name: "Компетентный",
          behaviour:
            "apply pathogenic specific biosecurity protocols in TAD or FAST disease suspicion (high disease transfer risk) : set up biocontainment zones, safely remove samples from infected premises, PPE, assess risk and insitute mitigating measures, in cases of biosecuirty breach in high disease transfer risk situations, lead biosecurity team during outbreak, use participatory approaches when explaining biosecurity recommendations to producer ",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Alert",
          PCP: "",
          PVS: "II-2 4",
          EuFMDCourses: "RRT",
          ISAVET: "Field Investigation /Response 7.1",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "Frontline 2.7 Intermediate 3.12b",
        },
        {
          name: "Профессионал",
          behaviour:
            "design and administer biosecurity training, adapt procedures based on response needs (required judgement and risk assesment), evaluate emerging technologies and makes recommendations based on sound veterinary information, developing and auditing industry biosecurity plans",
          role: "Veterinary advisor",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "II-2 A 4",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "",
          USAID: "Intermediate 3.12b",
        },
        {
          name: "Эксперт",
          behaviour:
            "develop biosecurity policies, predict likelihood of achieving goals, approve veterinary response decisions, foresee impacts of veterinary decisions (e.g., benefits, liability, national, stakeholders)",
          role: "Policy developer",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "II-2 4",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "",
          USAID: "Intermediate 3.12b",
        },
      ],
    },
    Sampling: {
      definition:
        "Collection of specimens, conducting regulatory diagnostic tests, and interpreting results",
      importance:
        "Results of sampling are used to make critical decisions in regard to declaring that a disease is present or that free status can be regained. Trust in the results are based on quality of samples collected, maintaining quality during shipment.",
      competency: "Sampling",
      differentLevels: [
        "Общая осведомленность",
        "Новичок",
        "Компетентный",
        "Профессионал"
      ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "describe the purpose of sampling, identify the situations that require testing, recognize the implications of sampling, find up-to-date and reliable information regarding specific sampling plans/techniques",
          role: "N/A",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "",
          USAID: "",
        },
        {
          name: "Новичок",
          behaviour:
            "outline the regulatory obligations of sampling, recognize the program under which the sampling/testing falls, recognize the zoonotics potential of a sample, report results to OV, collect, samples for diagnostic or testing purpose (antemortem and post mortem), package for shipping ensure the integrity of the samples. (includes safe collection, handling, and knowledge of transportation of dangerous goods)",
          role: "Private practitioner hirer into VS Day 1 hire into VS Veterinary Paraprofessional",
          getPrepared: "Alert",
          PCP: "2,3",
          PVS: "II-4AB 2,3",
          EuFMDCourses: "FEPC FITC RRT Wildlife surv ASF LSD",
          ISAVET: "Field Investigation/Response 6.2",
          BTSF: "Animal disease Preparedness/ early warning/ contingency planning/ disease control",
          USAID: "Frontline 2.9b-c Intermediate 2.8biv",
        },
        {
          name: "Компетентный",
          behaviour:
            "provide advice on the collection of samples for diagnostic or testing purpose (antemortem and post mortem), interpret and explain results (onsite and lab.), discuss factors affecting test outcomes, describe mechanism of diagnostic tests, define diagnostic test performance terminology, explain limitations of each test, trains industry or other 3rd party samplers, coordinates self sampling plans",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Alert",
          PCP: "2,3",
          PVS: "II-4AB 2,3",
          EuFMDCourses: "FEPC FITC RRT Wildlife surv ASF LSD",
          ISAVET: "",
          BTSF: "",
          USAID: "Intermediate 2.8aiv",
        },
        {
          name: "Профессионал",
          behaviour:
            "develops sampling plans given the characteristics of the disease and sampling situation (individual vs. herd, surveillance vs. suspicion, this involves review of scientific literature, international standards and new methodologies)",
          role: "Policy developer, Veterinary advisor",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "",
          EuFMDCourses: "FLITC",
          ISAVET: "",
          BTSF: "",
          USAID: "",
        },
      ],
    },
    "ID and Movement Control": {
      definition:
        "The combination of the identification and registration of an animal individually, with a unique identifier, or collectively by its epidemiological unit or group, with a unique group identifier. It includes the ability to regulate the identification of animals, and track and monitor domestic movements during all stages of life and linking of components such as identification of establishments or owners, the persons responsible for the animals, movements and other records with animal identification. This includes jointly managed programs (public-private partnerships) in normal disease status and emergency situations (control zones and movement from premises)",
      importance:
        "The ability to identify animals (individually, by batch, by premise) is the foundation for many disease control activities such as outbreak suspicion and confirmation management, vaccination and tracing movements, without a means of identification more animals/premises must be consider to be potential implicated in a outbreak slowing response time and increasing demand on resources",
      competency: "ID and Movement Control",
      differentLevels: [
        "Общая осведомленность",
        "Новичок",
        "Компетентный",
        "Профессионал",
        "Эксперт"
      ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "describe methods to identify animals, define a control zone, describe the purpose of a control zone, describe methods used to control movement of animals, animal products, equipment, and people, describe industry movement patterns for animals, products and by products.",
          role: "Day 1 hire into VS",
          getPrepared: "Foundations",
          PCP: "3",
          PVS: "II-12A 3 II-12B 2",
          EuFMDCourses: "FEPC/FIT, RTT, ASF, LSD +, other workshops",
          BTSF: "Animal disease Preparedness/ early warning/ contingency planning/ disease control",
        },
        {
          name: "Новичок",
          behaviour:
            "describe established programs for animal identification, describe the principles of control zones, apply identification method (ear tag, chip etc.), control animal movement off a suspect/infected premises (includes regulatory authority documentation), member of a zone movement control team during an outbreak, use ID database (National and/or EU) to trace animal movement history, etc.",
          role: "Private practitioner hirer into VS Veterinary Paraprofessional",
          getPrepared: "Emergency",
          PCP: "3",
          PVS: "II-12 A 3 II-12 B 2",
          EuFMDCourses: "FEPC/FIT, RTT, ASF, LSD +, other workshops",
          BTSF: "",
        },
        {
          name: "Компетентный",
          behaviour:
            "educate producers on animal ID program benefits and requirements, certification of peace time animal movement (national or EU level), oversees local level public-private partnerships (leading a team, accreditation and/or audit) in support of animal ID program, control animal movement within/in and out of a control zone (includes regulatory authority documentation), lead a team to enforce control zones during an outbreak, determine if situations qualifies for as known exemption",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Foundations Emergency",
          PCP: "3",
          PVS: "II-12 A 3 II-12 B 2",
          EuFMDCourses: "FEPC/FIT, RTT, ASF, LSD +, other workshops",
          BTSF: "Animal disease Preparedness/ early warning/ contingency planning/ disease control",
        },
        {
          name: "Профессионал",
          behaviour:
            "determines limits of a control zone, modify control zones as required, recognizing factors that impact movement control decision, allocate resources for enforcement, design and deliver movement control training, determine if situations qualifies for a unique or unusual exemption",
          role: "Veterinary advisor",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "",
          BTSF: "",
        },
        {
          name: "Эксперт",
          behaviour:
            "write and modify movement control polices and programs based on epidemiology, risk analysis/planning, monitor results, to support the continual improvement of policies and programmes over time, work with partners to identify resources for enforcement, predict likelihood of achieving goals, foresee impacts of veterinary decisions (e.g., benefits, liability, national, stakeholders). ",
          role: "Policy developer",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "II-12 4,5 II-12 B 2",
          EuFMDCourses: "",
          BTSF: "",
        },
      ],
    },
    "Emergency and Disaster Management": {
      definition:
        "Refers to the organizational structure and  processes/practices, of coordination of an emergency including activites such as response, control programmes and communication take place",
      competency: "Emergency and Disaster Management",
      differentLevels: [
        "Общая осведомленность",
        "Новичок",
        "Компетентный",
        "Профессионал",
        "Эксперт"
      ],
      levels: [
        {
          name: "Общая осведомленность",
          behaviour:
            "describe the phases of an emergency event and the veterinary roles in preparedness, response, recovery and prevention or mitigation, identify legislation governing emergency response, explain integration and coordination between governmental and non-governmental responders, authorities, and public and private entities, explain the concept of incident coordination mechanisms, their functional capacity and the role of veterinarians, including in indirect aspects such as business continuity, food and feed security and animal welfare, participate in a emergency response under direction",
          role: "Private practitioner hirer into VS Day 1 hire into VS Veterinary Paraprofessional",
          getPrepared: "Foundations/ Emergency",
          PCP: "2,3",
          PVS: "I-6 A 3",
          EuFMDCourses: "Managing a Crisis",
          ISAVET: "Preparedness 11.1",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Новичок",
          behaviour:
            "apply the concept of incident coordination mechanisms, their functional capacity and the role of veterinarians, during a single event or initial incident, direct the work of others in VS and contractors at field level during the response",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Emergency",
          PCP: "2,3",
          PVS: "I-6 A 3",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Компетентный",
          behaviour:
            "apply the concept of incident coordination mechanisms, their functional capacity and the role of veterinarians, in an expanding/complex incident,  take a specific functional position (task team leader) within the local emergency structure, direct the action of non VS staff ( contractors, external stakeholders) at the local level, crisis communication at local level, identify the sectors that may be involved in addressing a multifaceted health event.",
          role: "Sr. Fully functional gov't field veterinarian (more experienced)",
          getPrepared: "Emergency",
          PCP: "2,3",
          PVS: "I-6 A 3",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "Frontline 3.11b",
        },
        {
          name: "Профессионал",
          behaviour:
            "apply the concept of incident coordination mechanisms, including multi agency coordination and indirect aspects such as business continuity, food and feed security and animal welfare during a complex and expanding incident, take a specific functional position ie epi lead within the emergency structure at the country region level, direct the actions of others at the local level, recognize political sensitivities, crisis communication at country region level",
          role: "Veterinary advisor",
          getPrepared: "Emergency",
          PCP: "2,3",
          PVS: "I-6 A 3",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "",
        },
        {
          name: "Эксперт",
          behaviour:
            "apply the concept of incident coordination mechanisms, including international coordination and communication during national incidents, take a specific functional position within the emergency structure at the national level, direct the actions of others at the national level, recognize political sensitivities and find solutions, crisis communication at national level",
          role: "Policy developer",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "I-6 A 4-5",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "",
        },
      ],
    },
    "Emergency Preparedness": {
      definition:
        "The ability to be prepared to respond rapidly to a disease outbreak or food safety emergency. Includes key activities around planning and exercises.",
      competency: "Emergency Preparedness",
      differentLevels: [
        "Awareness",
        "Beginner",
        "Competent",
        "Proficient",
        "Expert",
      ],
      levels: [
        {
          name: "Awareness",
          behaviour:
            "describe the role of emergency preparedness planning (including training and exercises) in controlling priority diseases, find disease, site or other preparedness plans (site specific plans, disease prevention, control and rapid response), describe the value of approaching preparedness from an all hazards-perspective",
          role: "Private practitioner hirer into VS Day 1 hire into VS",
          getPrepared: "N/A",
          PCP: "2,3",
          PVS: "II-5 3",
          EuFMDCourses: "FEPC FIPC ASF LSD",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Beginner",
          behaviour:
            "uses preparedness plans, participates in preparedness at local level (contact lists, participate in training), recognizes common triggers for investigation (local situational awareness), explain regs and SOPs relevant to position",
          role: "N/A",
          getPrepared: "N/A",
          PCP: "",
          PVS: "",
          EuFMDCourses: "",
          BTSF: "",
          USAID: "Frontline 2.6A",
        },
        {
          name: "Competent",
          behaviour:
            "develop site specific emergency plan (slaughterhouse, market, ), develop sample collection logistics plan, exercise site specific emergency plans, recognize factors that impact ability to respond, recognize suspicious biological events (including clusters, case definition and triggers)",
          role: "Field veterinarian veterinary paraprofessional",
          getPrepared: "Foundations",
          PCP: "2,3",
          PVS: "II-5 3",
          EuFMDCourses: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "Frontline 1.2A Intermediate 2.8aii",
        },
        {
          name: "Proficient",
          behaviour:
            "write operational manuals/SOP based on national plans, support design and delivery of training, design and monitor simulation exercises, identify gaps in training or policy through after action reporting, support industry in development of their Business continuity and response plans, develop educational material for industry",
          role: "Veterinary advisor",
          getPrepared: "Foundations",
          PCP: "3",
          PVS: "II-5 4",
          EuFMDCourses: "Sim Ex",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "Intermediate 3.9i",
        },
        {
          name: "Expert",
          behaviour:
            "write or updates national plans based on after action reports, latest scientific knowledge, legislation, risk analysis develops auditing tools/SOPs for verification of activated plans",
          role: "Policy developer",
          getPrepared: "Foundations",
          PCP: "3",
          PVS: "II-5 4-5",
          EuFMDCourses: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "",
        },
      ],
    },
    "Emergency response": {
      definition:
        "The ability to respond rapidly to a disease outbreak or food safety emergency, includes the ability to conduct an initial investigation and take a particular role (valuation, disposal, cleaning and disinfection, wildlife controls during an emergency).",
      competency: "Emergency Response",
      differentLevels: [
        "Awareness",
        "Beginner",
        "Competent",
        "Proficient",
        "Expert",
      ],
      levels: [
        {
          name: "Awareness",
          behaviour:
            "describe activities (and their purpose) of an incident response, locate guidance documents/SOPs",
          role: "N/A",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "II-5 2",
          EuFMDCourses: "",
          ISAVET: "Preparedness 11.2",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Beginner",
          behaviour:
            "use disease specific plans to prepare for initial investigation, describe case definition, assist an initial or outbreak investigation (see epi, sampling, movement controls, biosecurity competencies), perform specific technical skills (under supervision) depending on the individuals role in outbreak (implement disposal methods, perform valuation, cleaning and disinfection, wildlife controls), is able to use required equipment, use veterinary expertise to collaborate and support law enforcement interventions.",
          role: "Private practitioner hirer into VS Day 1 hire into VS Veterinary Paraprofessional",
          getPrepared: "Emergency",
          PCP: "2,3",
          PVS: "II-5 3 II-6 3 IV-6 2,3 II-3 3",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Competent",
          behaviour:
            "independently perform specific technical skills depending on the individuals role in outbreak (implement disposal methods, perform valuation, cleaning and disinfection, wildlife controls), search for new cases - maintain equipment - support and instruct responders in task related to role (lead team), conduct an initial or outbreak investigation (see epi, sampling, movement controls, biosecurity competencies), law enforcement related activities, scene preservation, chain of custody of samples/evidence, what information to allow for successful investigation/prosecution.",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Emergency",
          PCP: "2,3",
          PVS: "II-5 3 II-6 3 IV-6 2,3 II-3 3",
          EuFMDCourses: "ASF",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "Frontline 2.8C",
        },
        {
          name: "Proficient",
          behaviour:
            "approve recommendations specific role protocols, design and selects response strategy, predict likelihood of achieving response goals, define outcomes",
          role: "Policy Developer",
          getPrepared: "Emergency",
          PCP: "3",
          PVS: "",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "",
          USAID: "",
        },
        {
          name: "Expert",
          behaviour:
            "apply the concept of incident coordination mechanisms, including international coordination and communication during national incidents, take a specific functional position within the emergency structure at the national level, direct the actions of others at the national level, recognize political sensitivities and find solutions, crisis communication at national level",
          role: "Policy developer",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "II-6 A 4-5",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "",
        },
      ],
    },
    "Veterinary Products": {
      definition:
        "means drugs, insecticides/acaricides, vaccines, and biological products used or presented as suitable for use to prevent, treat, control, or eradicate animal pests or diseases; having a prophylactic, therapeutic or diagnostic effect, alter physiological functions or to be given to animals to establish a veterinary diagnosis; or to restore, correct or modify organic functions in an animal or group of animals.",
      competency: "Veterinary Products",
      differentLevels: [
        "Awareness",
        "Beginner",
        "Competent",
        "Proficient",
        "Expert",
      ],
      levels: [
        {
          name: "Awareness",
          behaviour:
            "advise on veterinary products to treat, manage and/or prevent common infectious and non-infectious diseases, basic reproductive conditions, trauma and other emergencies of domesticated animal species, describe the characteristics, categories and prudent use of veterinary products, determine species of animal, route of administration, dosage of drug, period of administration, administer veterinary products/biologics, communicate adverse effects, including development of antimicrobial resistance, store and handle products (including cold chain requirements), explain and determine drug withdrawal time, apply regulatory and administrative controls over AMR, AMU, participates in AMR/AMU surveillance, demonstrate awareness of when to seek professional support and advice",
          role: "Veterinary Paraprofessional",
          getPrepared: "Foundations/ Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "VVPV",
        },
        {
          name: "Beginner",
          behaviour:
            "prescribe veterinary products, describe mechanisms leading to development of antimicrobial resistance, find and interpret information regarding the link between use of antimicrobials in food animals and development of antimicrobial resistance in pathogens of human importance, demonstrate awareness of where to seek professional support and advice, communicate this information to authorities and stakeholders",
          role: "Private practitioner hirer into VS Day 1 hire into VS",
          getPrepared: "Foundations/Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "FEPC VVPV",
        },
        {
          name: "Competent",
          behaviour:
            "describe regulatory and administrative controls for import, manufacture and registration of veterinary biologics, determine compliance with regulations ( ie audit of manufacturers, import inspections etc.), implement AMR surveillance",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Foundations/Emergency",
          PCP: "2,3",
          PVS: "II-8 3,4",
          EuFMDCourses: "VVPV PVM",
        },
        {
          name: "Proficient",
          behaviour:
            "approve new products, support design of AMR surveillance progra",
          role: "Veterinary advisor",
          getPrepared: "Foundations/ Emergency",
          PCP: "2,3",
          PVS: "II-8 3,4",
          EuFMDCourses: "",
        },
        {
          name: "Expert",
          behaviour:
            "design AMR surveillance programs (NAP), write and update policies for regulating veterinary medicines and biologics (based on latest science, OIE best practices, current conditions), recognise the implication of emergencies on supply chain interruptions and international restrictions on movement of biological products",
          role: "Policy developer",
          getPrepared: "Foundations/ Emergency",
          PCP: "2,3",
          PVS: "II-8 4,5",
          EuFMDCourses: "VVPV PVM",
        },
      ],
    },
    "Animal Welfare": {
      definition:
        "means the physical and mental state of an animal (how it is coping) in relation to the conditions in which it lives and dies.  An animal is in a good state of welfare if (as indicated by scientific evidence) it is healthy, comfortable, well nourished, safe, able to express innate behaviour, and if it is not suffering from unpleasant states such as pain, fear, and distress. Good animal welfare requires disease prevention and veterinary treatment, appropriate shelter (when relevant), management, nutrition, humane handling, and humane slaughter/killing. Animal welfare refers to the state of the animal",
      competency: "Animal Welfare",
      differentLevels: [
        "Awareness",
        "Beginner",
        "Competent",
        "Proficient",
        "Expert",
      ],
      levels: [
        {
          name: "Awareness",
          behaviour:
            "explain the importance of the human animal bond, describe the expression of pain and distress of relevant animal species (consider age, breed, physiological or pathological changes), use animal handling techniques and equipment, explain animal welfare and the related responsibilities of owners, handlers, veterinarians, find information regarding local, national and international animal welfare regulations/standards, describe humane methods for: animal production; transport; slaughter for human consumption and killing for disease control purposes. Describe emergency and disaster management implications on animal welfare",
          role: "Day 1 hire into VS",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "I-13 3",
          EuFMDCourses: "",
          ISAVET: "Ethics/Professionalism 11.3",
        },
        {
          name: "Beginner",
          behaviour:
            "implement animal welfare compliance program, identify animal welfare problems (may involve scoring systems assurance programmes) report and participate in corrective actions), perform humane euthanasia on individual animals (captive bolt, cervical dislocation etc.)includes ability to care for and maintain equipment required, evaluate humane slaughter stunning/bleeding, determine method of restraint (chemical physical) considering welfare concerns, recognize the implications of emergencies and disasters on animal welfare",
          role: "Private Practitioner hirer into VS Veterinary Paraprofessional",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "II-13 3,4,5",
          EuFMDCourses: "",
          ISAVET: "Ethics/Professionalism 11.3",
        },
        {
          name: "Competent",
          behaviour:
            "implement humane destruction methods for mass depopulations, includes ability to care for and maintain equipment required, lead team, summarise the animal health and welfare implications of control measures (such as movement controls), perform necropsy in welfare investigation",
          role: "Fully functional gov't field veterinarian",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "II-13 3,4,5",
          EuFMDCourses: "",
          ISAVET: "Ethics/Professionalism 11.3",
        },
        {
          name: "Proficient",
          behaviour:
            "provide guidance to field staff on implementation and interpretation of welfare programs in complex situations, select humane destruction methods for mass depopulations (CO2, foaming, captive bolt, free bullet, pharmaceutical etc.) such as preventative culling, welfare slaughter or destruction during disease outbreaks, adapt procedures based on response needs, design training, evaluate emerging technologies and make recommendations",
          role: "Veterinary advisor",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "II-13 3,4,5",
          EuFMDCourses: "",
          ISAVET: "",
        },
        {
          name: "Expert",
          behaviour:
            " write and update compliance programs using scientific articles, international policies and guidelines includes working with stakeholders, predict social acceptance for response actions, foresee impacts of veterinary decisions",
          role: "Policy developer",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "II-13 3,4,5",
          EuFMDCourses: "",
          ISAVET: "",
        },
      ],
    },
    "Risk Analysis": {
      definition:
        "Risk means the likelihood of the occurrence and likely magnitude of the biological and economic consequences of an adverse event or effect to animal or human health. (Risk = likelihood X consequences) The process of risk analysis involves hazard identification, risk assessment, risk management, and risk communication.",
      competency: "Risk Analysis",
      differentLevels: [
        "Awareness",
        "Beginner",
        "Competent",
        "Proficient",
        "Expert",
      ],
      levels: [
        {
          name: "Awareness",
          behaviour:
            "explain risk analysis concepts: hazard identification, risk assessment, rapid risk assessment, risk management, risk communication, explain how risk analysis can be used to ensure veterinary services adequately protect animal and human health, the environment in emergency, non emergencies and natural disasters",
          role: " Day 1 hire into VS Private practitioner hirer into VS",
          getPrepared: "N/A",
          PCP: "0",
          PVS: "",
          EuFMDCourses: "RAVC Risk analysis and trade Managing a crisis",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Beginner",
          behaviour:
            "find information needed for risk analysis, explain how risk analysis can be applied to assessment of risk of animal disease emergency planning/mitigation/preparation response and recovery, residues of veterinary drugs, including importation of animals and animal products",
          role: "N/A",
          getPrepared: "N/A",
          PCP: "0",
          PVS: "",
          EuFMDCourses: "",
          ISAVET: "",
          BTSF: "Animal disease Preparedness/ early warning/ contigency planning/ disease control",
          USAID: "",
        },
        {
          name: "Competent",
          behaviour:
            "use basic/key concepts and principles of risk analysis in accordance with prescribed policy and procedures/guidelines (disease present is TAD or FAST), convey disease transmission risks, map local value chains",
          role: "Fully functional gov't field veterinarian Veterinary Paraprofessional",
          getPrepared: "Alert",
          PCP: "1,2,3",
          PVS: "II-2 3",
          EuFMDCourses: "RAVC",
          ISAVET: "Communication 10.2 Preparadness 11.3",
          BTSF: "Animal disease Preparedness/ early warning/contingency planning/disease control",
          USAID: "Intermediate 1.5a-biii",
        },
        {
          name: "Proficient",
          behaviour:
            "apply risk analysis principles in unusual/complex situations, support field OV in decision making especially when deviations from prescribed policy are warranted or situation is complex (expanding outbreak), determine risk communication measures (understanding of clear roles and responsibilities), liaison between risk assessment, risk management and risk communication, review existing and new threats, identify disease transmission risks",
          role: "Veterinary advisor",
          getPrepared: "Alert",
          PCP: "",
          PVS: "",
          EuFMDCourses: "Managing a crisis Risk based Surv",
          ISAVET: "",
          BTSF: "",
          USAID: "Intermediate 1.7 Intermediate 3.9iv",
        },
        {
          name: "Expert",
          behaviour:
            "carry out organization level risk analysis (identify and analyse disease transmission pathways and associated value chains to identify risk hotspots for FAST entry and spread), provide risk assessment results to support risk management and policy/program decisions (identify control options, appraisal of the expected impact and fesibility of identified control options) and support risk communication, support development of risk communication strategy",
          role: "Policy developer",
          getPrepared: "Foundations",
          PCP: "",
          PVS: "II-2 4-5",
          EuFMDCourses: "RAVC Risk analysis and trade workshop Risk based Surv",
          ISAVET: "",
          BTSF: "",
          USAID: "Intermediate 1.5biv-v",
        },
      ],
    },
    "Safety, Health and Well being": {
      definition:
        "describes the ability to protect physical and mental health of oneself and those who are working around them.",
      competency: "Safety, Health and Wellbeing",
      differentLevels: [
        "Awareness",
        "Beginner",
        "Competent",
        "Proficient",
        "Expert",
      ],
      levels: [
        {
          name: "Awareness",
          behaviour:
            "explain the concepts of situational awareness -describe the work space hazards including demanding work hours/conditions/tasks and traumatic, environmental, chemical, and radiological hazards and mitigating processes. -describe infectious and non-infectious health hazards and mitigating processes, describe the behavioral/psychological health risks for veterinary professionals and processes to mitigate behavioral health issues and increase resilience, recognise principles of animal, human, and environment health and safety in emergencies and disasters to allow for recognition and strategic response to One Health impacts, describe emergency and disaster management implications on livelihoods.",
          role: " Day 1 Private practitioner hirer",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "FAST disease crisis mgt",
        },
        {
          name: "Beginner",
          behaviour:
            "recognize signs of deteriorating wellbeing in self, identify sources of support (personal and organizational), recognize unsafe work environments",
          role: "N/A",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "FAST disease crisis mgt",
        },
        {
          name: "Competent",
          behaviour:
            "recognize signs of deteriorating well being in others (mental first aid), intervention to prevent further harm (referral to support, contact authorities), participate in process to report events, identify root cause and make recommendations, recognize unsafe work environments and take action to correct",
          role: "Field veterinarian Veterinary Paraprofessional",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "FAST disease crisis mgt",
        },
        {
          name: "Proficient",
          behaviour:
            "lead investigation into accidents or near misses, make recommendations to reduce the likelihood of mental or physical health hazards",
          role: "advisor",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "",
        },
        {
          name: "Expert",
          behaviour:
            "write/update policy and procedures to protect employee safety, health and wellbeing",
          role: "Policy writer",
          getPrepared: "Emergency",
          PCP: "",
          PVS: "",
          EuFMDCourses: "",
        },
      ],
    },
  };
  