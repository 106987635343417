export default {
  "navigation": {
    "country_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director nacional"])},
    "side_navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel de navegación lateral"])}
  },
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Datos de Usuario"])},
    "adjust_competency_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustar los Niveles de Competencia"])},
    "custom_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes Personalizados"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos"])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marco de Competencias"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Créditos de Formación"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidato"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar un curso personalizado"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funciones Futuras"])},
    "leave_a_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar una Sugerencia"])}
  },
  "cmHome": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visión General"])},
    "view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Mapa"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вскоре"])},
    "learners_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiantes registrados"])},
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiantes"])},
    "participants_courses_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de los cursos de los participantes"])},
    "tailored_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos personalizados"])},
    "real_time_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes de los cursos en tiempo real"])},
    "workshop_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes de los talleres"])},
    "online_course_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes de los cursos en línea"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Créditos de Formación"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En progreso"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar un Curso Personalizado"])},
    "average_number_of_courses_per_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número Medio de Cursos por Participante "])},
    "compentencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencias"])},
    "select_a_position_to_see_competencies_related_to this_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un cargo para ver las competencias relacionadas con ese cargo"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Posición"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar nivel"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Región"])},
    "select_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Competencia"])},
    "assign_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignar nivel "])},
    "learners_in_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiantes totales: "])},
    "learners_with_this_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiantes con este cargo: "])},
    "overview_of_required_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de competencias requeridas"])},
    "select_a_position_above_to_see_the_competency_levels_of_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar uno de los cargos de los de arriba para ver los niveles de competencia de los estudiantes en ese cargo."])},
    "competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la competencia"])},
    "learn_more_about_the_competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtén más información sobre el marco de competencias"])},
    "select_a_position_to_see_competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un cargo de los de arriba para ver detalle de las competencias requeridas para ese cargo."])},
    "number_of_learners_per_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de estudiantes por competencia"])},
    "click_to_view_more_details_chart_on_the_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer clic en una competencia para ver más detalles. Gráfico a la derecha."])},
    "you_are_seeing_data_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás viendo datos para"])},
    "Epidemiology_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La epidemiología es el estudio de los factores que determinan e influyen en la frecuencia y distribución de las enfermedades, así como en otros acontecimientos relacionados con eventos sanitarios, y sus causas/factores de riesgo y/o protección en una población definida con el propósito de controlar su desarrollo y propagación mediante el establecimiento de programas de prevención y control."])},
    "TADS_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Enfermedades Transfronterizas de los animales (ETAs) son enfermedades altamente contagiosas o transmisibles y con un potencial de propagación muy rápido, independientemente de las fronteras nacionales. El perfil de la enfermedad se refiere a los signos clínicos, la etiología, la patogénesis, el curso clínico, el potencial de transmisión y la epidemiología de la enfermedad. El control y la prevención se encuentran dentro de la competencia de control y prevención de enfermedades. Las ETAs incluyen entre otras la peste porcina africana y clásica, la influenza aviar altamente patógena, la pleuroneumonía contagiosa bovina, la septicemia hemorrágica de los peces, la peste bovina, la fiebre del valle del Rift, la viruela ovina y caprina, la Peste de los pequeños rumiantes, la dermatosis nodular contagiosa y la fiebre aftosa."])}
  },
  "Epidemiology": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La epidemiología es el estudio de los factores que determinan e influyen en la frecuencia y distribución de las enfermedades, así como en otros acontecimientos relacionados con eventos sanitarios, y sus causas/factores de riesgo y/o protección en una población definida con el propósito de controlar su desarrollo y propagación mediante el establecimiento de programas de prevención y control."])}
  },
  "Transboundary Animal Diseases": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Enfermedades Transfronterizas de los animales (ETAs) son enfermedades altamente contagiosas o transmisibles y con un potencial de propagación muy rápido, independientemente de las fronteras nacionales. El perfil de la enfermedad se refiere a los signos clínicos, la etiología, la patogénesis, el curso clínico, el potencial de transmisión y la epidemiología de la enfermedad. El control y la prevención se encuentran dentro de la competencia de control y prevención de enfermedades. Las ETAs incluyen entre otras la peste porcina africana y clásica, la influenza aviar altamente patógena, la pleuroneumonía contagiosa bovina, la septicemia hemorrágica de los peces, la peste bovina, la fiebre del valle del Rift, la viruela ovina y caprina, la Peste de los pequeños rumiantes, la dermatosis nodular contagiosa y la fiebre aftosa."])}
  },
  "userData": {
    "single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario individual"])},
    "bulk_user_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga masiva de usuarios"])},
    "add_a_single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un usuario individual"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Posición"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Datos de Usuario"])},
    "csv_upload_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí tienes una plantilla para el formato del archivo de carga.CSV"])},
    "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar plantilla"])},
    "cvs_confirmation_positions_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrate de que estos cargos se ingresen en tu archivo .CSV exactamente como aparecen aquí. Estos son los cargos con competencias relacionadas con ellos en tu país."])},
    "upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga tu archivo .CSV aquí"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustar Niveles"])},
    "click_to_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga Clic para Guardar"])}
  },
  "adjustCompetency": {
    "position_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencias del cargo"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencias"])},
    "level_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel requerido"])},
    "view_and_adjust_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver y ajustar la competencia"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por puesto o competencia"])}
  },
  "learners": {
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiantes"])},
    "change_the_position_or_region_in_the_header_above_to_filter_the_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar el cargo o la región en la cabecera de arriba para filtrar en la tabla"])},
    "all_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los estudiantes"])},
    "only_learners_with_competency_gaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo estudiantes con carencias de competencia"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "competencies_at_required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencias del nivel requerido"])},
    "enrol_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscribir estudiantes"])},
    "mark_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como completo"])},
    "date_enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inscripción"])},
    "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización"])},
    "add_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir estudiante"])},
    "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir fecha"])},
    "enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos en los que se ha inscrito"])},
    "enrolled_course_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalización del curso en el que se ha inscrito"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin región"])},
    "list_of_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cargos"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveles"])},
    "no_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin Nivel"])},
    "awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conocimiento básico y sensibilización"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principiante"])},
    "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competente"])},
    "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capaz"])},
    "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experto"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir datos de usuario"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por puesto o competencia"])},
    "changing_competency_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiando el nivel de competencia de"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe personalizado"])},
    "select_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar categoría de curso"])},
    "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar informe"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los cursos"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis cursos"])},
    "search_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar tu curso. Si tu curso no aparece en la lista, tendrás la opción de añadir un nuevo curso."])},
    "course_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del curso"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
    "shortname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre abreviado/sigla"])},
    "choose_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir Estudiante"])},
    "choose_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir Curso"])},
    "choose_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir Curso Categoría"])},
    "add_a_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un nuevo curso."])},
    "if_course_not_above_add_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu curso no aparece arriba o en la lista de todos los cursos, haz clic en el botón a continuación para añadir un nuevo curso."])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marco de Competencias"])},
    "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar este documento"])},
    "click_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer clic en una competencia para ver más información al respecto, "])},
    "click_competency_framework_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o aquí para consultar los detalles del Marco de Competencias."])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel actual"])},
    "required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel requerido"])},
    "enrolled_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías inscritas"])},
    "add_current_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Tu región"])},
    "enter_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir región"])}
  },
  "courseList": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar un curso ..."])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no has añadido ningún curso."])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])}
  },
  "singleLearner": {
    "competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencia"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos "])},
    "self_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluación"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "year_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año completado"])},
    "no_open_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes cursos abiertos"])},
    "no_closed_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes cursos completados"])},
    "get_prepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepararse"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
    "pcp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCP"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidaste tu contraseña?"])}
  },
  "selfAssessment": {
    "section_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 1"])},
    "section_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 2"])},
    "section_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 3"])},
    "section_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 4"])},
    "section_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 5"])},
    "section_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 6"])},
    "section_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 7"])},
    "section_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 8"])},
    "section_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 9"])},
    "section_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 10"])},
    "section_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 11"])},
    "section_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 12"])},
    "section_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECCIÓN 13"])},
    "epi_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de los principios de epidemiología y de su aplicación en procedimientos generales de control de enfermedades (Procedimientos Operativos Estándar (POE))."])},
    "epi_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sé cómo realizar una investigación epidemiológica en el caso de aparición de una enfermedad de declaraciónobligatoria."])},
    "epi_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de los enfoques epidemiológicos utilizados durante emergencias animales y sus impactos (individuo vs población)."])},
    "epi_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con la orientación adecuada, puedo colaborar en la recopilación de datos epidemiológicos durante un brote de una enfermedad de declaración obligatoria."])},
    "epi_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo liderar una investigación epidemiológica en un establecimiento bajo sospecha/confirmado, incluyendo la determinación de las vías de transmisión de una enfermedad dentro del establecimiento."])},
    "epi_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo evaluar la calidad de los datos de la investigación de enfermedades."])},
    "epi_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo determinar el origen de un brote de una enfermedad y asegurarme de que esté respaldada por un análisis epidemiológico adecuado."])},
    "epi_1_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo llevar a cabo un análisis de riesgos."])},
    "epi_1_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.9 Soy capaz de desarrollar planes de respuesta estratégicos y prever la probabilidad de lograr sus objetivos."])},
    "epi_1_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo liderar la planificación anticipada para controlar un brote y la fase de recuperación."])},
    "tads_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengo conocimiento (especies afectadas, signos clínicos, etc.) sobre enfermedades animales transfronterizas comunes (FA, DNC, PPA, FVR) y las herramientas utilizadas para su prevención y control."])},
    "tads_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sé dónde encontrar información actualizada sobre la distribución de enfermedades."])},
    "tads_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de las enfermedades animales transfronterizas de declaración obligatoria y puedo describir el patógeno, las lesiones, la transmisión, los métodos de muestreo y diagnóstico, así como cualquier necesidad de equipo de protección individual (EPI)."])},
    "tads_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozco los sistemas nacionales de información y sé cuáles son las enfermedades animales transfronterizas de declaración obligatoria."])},
    "tads_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo realizar exámenes clínicos de animales individuales y rebaños sospechosos de estar afectados por una enfermedad animal transfronteriza, estimar la antigüedad de las lesiones, determinar qué animales muestrear y explicar la lógica de las herramientas diagnósticas y terapéuticas."])},
    "tads_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo diagnosticar con certeza una sospecha de enfermedad animal transfronteriza y notificar según sea necesario."])},
    "tads_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo dar recomendaciones apropiadas cuando los signos clínicos son confusos."])},
    "tads_2_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de la importancia de las enfermedades animales transfronterizas a nivel nacional, incluido su impacto socioeconómico."])},
    "disease_prev_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de los principios del control de enfermedades animales transfronterizas y sus programas de control."])},
    "disease_prev_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de qué enfermedades animales requieren notificación obligatoria y la importancia del 'sistema de detección temprana', incluyendo cómo acceder a información relevante sobre estas enfermedades y cómo actuar frente a ellas."])},
    "disease_prev_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy capacitado para participar en planes de control de enfermedades (por ejemplo, programas de vigilancia, campañas de vacunación, monitorización post-vacunación, programas de prueba y sacrificio/tratamiento)."])},
    "disease_prev_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo liderar planes de control de enfermedades, y coordinarme con todos los agentes involucrados para una eficiente implementación de los mismos."])},
    "disease_prev_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo supervisar asociaciones público-privadas a nivel local (incluido liderar un equipo, acreditación, auditoría)."])},
    "disease_prev_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo guiar a otros en la implementación de programas en situaciones complejas y adaptar procedimientos según las necesidades de respuesta."])},
    "disease_prev_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar asociaciones público-privadas a nivel local."])},
    "disease_prev_3_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo redactar programas de control de enfermedades basados en las mejores prácticas y evaluar su rentabilidad."])},
    "disease_prev_3_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar asociaciones público-privadas a nivel nacional."])},
    "bio_security_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de las medidas de bioseguridad en las explotaciones ganaderas y puedo identificar los entornos en los que se necesita bioseguridad."])},
    "bio_security_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente del impacto que supone el incumplimiento de las medidas de bioseguridad y como mitigarlo."])},
    "bio_security_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo participar de manera efectiva en un brote de enfermedad como miembro de un equipo de bioseguridad, incluyendo entrar y salir de las instalaciones de manera biosegura."])},
    "bio_security_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo reconocer el incumplimiento de las medidas de bioseguridad y corregirlas aplicando las medidas de mitigación apropiadas en situaciones de bajo riesgo."])},
    "bio_security_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo aplicar protocolos de bioseguridad específicos para patógenos en situaciones de alto riesgo de propagación de enfermedades."])},
    "bio_security_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo liderar un equipo de bioseguridad durante un brote."])},
    "bio_security_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo adaptar procedimientos según las necesidades de respuesta."])},
    "bio_security_4_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar y auditar planes de bioseguridad para la industria."])},
    "bio_security_4_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo evaluar decisiones de respuesta veterinaria y prever la probabilidad de alcanzar sus objetivos."])},
    "bio_security_4_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar protocolos de bioseguridad efectivos."])},
    "sampling_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de los principios del muestreo, incluyendo la identificación de situaciones que requieren muestreo y las implicaciones del mismo."])},
    "sampling_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sé donde encontrar información fiable sobre planes y técnicas de muestreo."])},
    "sampling_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozco las normativas sobre muestreo, programas y potencial zoonótico de las enfermedades animales ."])},
    "sampling_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo recopilar muestras apropiadas y empaquetarlas (asegurando su integridad)."])},
    "sampling_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo brindar asesoramiento sobre la recolección de muestras para pruebas diagnósticas, interpretar resultados, discutir factores que afectan los resultados de las pruebas, describir mecanismos de las pruebas diagnósticas, definir pruebas diagnósticas, su rendimiento y limitaciones."])},
    "sampling_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo formar a otras personas en técnicas de muestreo apropiadas."])},
    "sampling_5_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar planes de muestreo efectivos dadas las características de la enfermedad y la situación de muestreo."])},
    "animal_id_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de los métodos utilizados para identificar animales y controlar el movimiento de animales, productos de origen animal, equipamientos y personas, incluyendo zonas de control."])},
    "animal_id_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozco los controles de movimiento industrial de animales, productos y subproductos/derivados en la industria."])},
    "animal_id_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo controlar eficazmente el movimiento de animales fuera de una instalación sospechosa/infectada y ser miembro de un equipo de control durante un brote."])},
    "animal_id_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo aplicar métodos de identificación a animales (por ejemplo, crotales auriculares y tatuajes) y utilizar una base de datos de identificación para rastrear el historial de movimiento de los animales."])},
    "animal_id_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo emitir certificados para el movimiento de animales en tiempos de paz."])},
    "animal_id_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo liderar un equipo para hacer cumplir zonas de control durante un brote y controlar el movimiento de animales dentro y fuera de una zona de control."])},
    "animal_id_6_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo determinar los límites de una zona de control, modificarla según sea necesario y determinar si una situación califica para una exención única."])},
    "animal_id_6_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo formar a otros en el control de movimiento."])},
    "animal_id_6_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo diseñar políticas adecuadas de control de movimiento y apoyar su mejora mediante la monitorización de los resultados del programa."])},
    "animal_id_6_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo prever con precisión la probabilidad de alcanzar objetivos y anticipar los impactos de decisiones veterinarias."])},
    "emergency_disaster_mgt_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de la legislación que rige la respuesta a emergencias, del papel de los veterinarios en situaciones de emergencia y la coordinación entre servicios de emergencia gubernamentales y no gubernamentales, autoridades, y entidades públicas y privadas."])},
    "emergency_disaster_mgt_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo la orientación adecuada, puedo participar en una respuesta de emergencia."])},
    "emergency_disaster_mgt_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo aplicar el concepto de mecanismos de coordinación de incidentes, su capacidad funcional y el papel de los veterinarios durante un incidente."])},
    "emergency_disaster_mgt_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo dirigir a otros durante respuestas de emergencia."])},
    "emergency_disaster_mgt_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo comunicar una crisis de manera efectiva a nivel local."])},
    "emergency_disaster_mgt_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo coordinar incidentes complejos e identificar los diferentes sectores involucrados."])},
    "emergency_disaster_mgt_7_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo dirigir un incidente complejo a nivel local."])},
    "emergency_disaster_mgt_7_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo comunicar una crisis a nivel regional."])},
    "emergency_disaster_mgt_7_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo dirigir respuestas de emergencia nacionales y la comunicación de una crisis a nivel nacional."])},
    "emergency_disaster_mgt_7_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo reconocer sensibilidades políticas y encontrar soluciones."])},
    "emergency_prep_8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente del papel de la planificación de preparación para emergencias para enfermedades prioritarias y cualquier plan de preparación pertinente."])},
    "emergency_prep_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozco el valor de abordar la preparación desde una perspectiva de riesgos múltiples."])},
    "emergency_prep_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo utilizar planes de preparacióny estoy al tanto de las regulaciones y de los Procedimientos Operativos Estándar (POE) relativos."])},
    "emergency_prep_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo participar con confianza en la preparación a nivel local."])},
    "emergency_prep_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar un plan de emergencia específico para un sitio (incluida la recopilación de muestras) y llevarlo a cabo."])},
    "emergency_prep_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo reconocer la sospecha de incursión de enfermedades y los factores que afectan a la capacidad de respuesta."])},
    "emergency_prep_8_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo colaborar en el diseño y ejecución de ejercicios de simulación y de capacitación."])},
    "emergency_prep_8_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo redactar manuales operativos y apoyar a la industria en el desarrollo de sus planes de respuesta y continuidad comercial."])},
    "emergency_prep_8_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo redactar y actualizar planes nacionales para el control de enfermedades."])},
    "emergency_prep_8_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo desarrollar herramientas de auditoría/POE para la verificación de planes activados."])},
    "emergency_res_9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy consciente de las actividades que deben incluirse en una respuesta a incidentes."])},
    "emergency_res_9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sé dónde localizar documentos de orientación/Procedimientos Operativos Estándar (POE)."])},
    "emergency_res_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo prepararme y ayudar en una investigación de brotes."])},
    "emergency_res_9_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo supervisión, puedo utilizar el equipo necesario y realizar actividades técnicas durante un brote (métodos de eliminación, valoración, limpieza y desinfección, control de la fauna silvestre)."])},
    "emergency_res_9_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo liderar a los servicios de emergencia durante un brote."])},
    "emergency_res_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo realizar actividades relacionadas con la aplicación de la ley (preservar la escena, cadena de custodia , etc.)."])},
    "emergency_res_9_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo formar a otros en la respuesta a una emergencia."])},
    "emergency_res_9_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo evaluar resultados, valorar las consecuencias de los cambios procedimentales y recomendar estrategias de respuesta."])},
    "emergency_res_9_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo aprobar recomendaciones y diseñar estrategias de respuesta."])},
    "emergency_res_9_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo definir resultados apropiados y prever la probabilidad de alcanzar objetivos de la respuesta."])},
    "vet_prod_10_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo asesorar y administrar productos veterinarios para tratar enfermedades comunes en animales."])},
    "vet_prod_10_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo aplicar controles reglamentarios y administrativos sobre la resistencia antimicrobiana (RAM), el uso de antimicrobianos (UAM) y participar en su vigilancia."])},
    "vet_prod_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo recetar productos veterinarios."])},
    "vet_prod_10_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo buscar e interpretar información sobre la relación entre el uso de antimicrobianos en animales destinados a la alimentación y el desarrollo de RAM en patógenos de relevancia para la salud pública."])},
    "vet_prod_10_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy al tanto de los controles reglamentarios y administrativos para la importación, fabricación y registro de productos biológicos veterinarios."])},
    "vet_prod_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo implementar la vigilancia de la resistencia antimicrobiana (RAM)."])},
    "vet_prod_10_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengo los conocimientos y habilidades necesarios para aprobar nuevos productos veterinarios, si se me asigna la tarea."])},
    "vet_prod_10_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo apoyar el diseño de programas de vigilancia de la resistencia antimicrobiana (RAM)."])},
    "vet_prod_10_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo diseñar programas y políticas de vigilancia de la resistencia antimicrobiana (RAM) para regular medicamentos y productos biológicos veterinarios."])},
    "vet_prod_10_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedo reconocer las implicaciones de emergencias en las interrupciones de la cadena de suministro y las restricciones internacionales sobre el movimiento de productos biológicos."])},
    "animal_welfare_11_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.1 Puedo identificar regulaciones/normas de bienestar animal y describir responsabilidades (dueños, veterinarios, manipuladores)."])},
    "animal_welfare_11_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.2 Puedo utilizar técnicas y equipos de manejo animal de manera que proteja el bienestar animal, reconociendo signos de dolor y sufrimiento."])},
    "animal_welfare_11_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.3 Puedo identificar y reportar problemas de bienestar animal, y participar en acciones correctivas."])},
    "animal_welfare_11_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.4 Puedo llevar a cabo el sacrificio sanitario de un animalutizando “métodos humanitarios”, incluyendo la evaluación de buenas prácticas en aturdimiento/sangrado y la aplicación de métodos de sujeción."])},
    "animal_welfare_11_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.5 Puedo liderar un equipo en el vaciado sanitario de animales siguiendo protocolos basados en métodos humanitarios de sacrificio."])},
    "animal_welfare_11_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.6 Puedo resumir las implicaciones sobre el bienestar animal de diversos métodos de control de enfermedades animales (por ejemplo, control de movimiento de animales)."])},
    "animal_welfare_11_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.7 Puedo guiar a otros en la implementación de programas de bienestar animal en situaciones complejas, y adaptar procedimientos según las necesidades de respuesta."])},
    "animal_welfare_11_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.8 Puedo seleccionar métodos humanitarios de sacrificio para el vaciado sanitario."])},
    "animal_welfare_11_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.9 Puedo redactar programas de cumplimiento utilizando literatura científica, reglamentos y directrices."])},
    "animal_welfare_11_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "risk_12_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "safety_13_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "rank_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, indique en qué medida está de acuerdo con las siguientes afirmaciones."])},
    "survey_sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secciones de la Encuesta"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a la Autoevaluación TOM"])},
    "welcome_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Autoevaluación TOM es una herramienta para ayudarte a comprender tu nivel de competencia actual en las áreas ubicadas en el Marco de Competencias de Control de Enfermedades. Cada sección está diseñada para ser completada en 10-15 minutos."])},
    "welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se te pedirá que califique su nivel de confianza en sus conocimientos o su capacidad para realizar tareas que representan distintos niveles de experiencia en las 13 competencias del marco."])},
    "click_survey_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer clic en cualquiera de las secciones de la encuesta en el panel lateral para empezar."])},
    "thank_you_for_completing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por completar la Autoevaluación TOM!"])},
    "assessment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "section_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones de la Encuesta"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalmente de acuerdo"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No estoy de acuerdo"])},
    "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplica"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoevaluación - Marco de Competencias de Control de Enfermedades"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])}
  },
  "routes": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de Sesión"])},
    "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer Contraseña"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominee"])},
    "request_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RequestCourse"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Créditos de Formación "])},
    "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerencias"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funciones Futuras"])},
    "singleLearner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudiante Único "])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marco de Competencia"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe Personalizado "])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Datos de Usuario "])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustar Niveles"])},
    "admin_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de Sesión de Administrador "])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
    "Learner Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción de Estudiante"])}
  },
  "positions": {
    "central_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veterinario central"])},
    "regional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veterinario regional"])}
  },
  "competencies": {
    "epidemiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiología"])},
    "tads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil de enfermedades animales transfronterizas"])},
    "disease_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programas de Prevención y Control de Enfermedades"])},
    "biosecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bioseguridad"])},
    "sampliing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestreo"])},
    "animal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación y Control del Movimiento Animal"])},
    "emergency_disaster_mgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Emergencias y Desastres"])},
    "emergency_prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparación para Emergencias"])},
    "emergency_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta de Emergencia"])},
    "vet_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos Veterinarios"])},
    "animal_welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienestar Animal"])},
    "risk_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application of Risk Analysis"])},
    "safety_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety, Health and Wellbeing"])}
  },
  "compLevels": {
    "Awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conocimiento básico y sensibilización"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principiante"])},
    "Competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competente"])},
    "Proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capaz"])},
    "Expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experto"])}
  },
  "certificates": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "date_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de subida"])},
    "no_certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay certificados para mostrar"])},
    "drag_n_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrastra y suelta archivos PDF aquí o haz clic para subir"])},
    "file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño máximo del archivo: 2 MB"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificados"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir nuevo certificado"])}
  },
  "externalCourses": {
    "external_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos externos"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organización"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona de contacto"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])}
  }
}