<template>
  <!-- access vuei18 obj from inside a component -->
  <div>
    <select class="py-2 bg-transparent"  v-model="selectedLocale" @change="updateLocale">
      <option
        class="text-green-primary"
        v-for="locale in locales"
        :key="locale.code"
        :value="locale.code"
      >
        {{ locale.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { getSupportedLocales } from "../utils/i18n/supported-locales";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      selectedLocale: this.$i18n.fallbackLocale,
      locales: getSupportedLocales(),
    };
  },
  methods: {
    updateLocale() {
      this.$i18n.locale = this.selectedLocale;
    }
  },
};
</script>

<style></style>
