export default {
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("cmHome.home", undefined, _type)])}
  },
  "cmHome": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Bakiş"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haritayi Göster"])}
  },
  "courseList": {
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursunuzu arayin. Kursunuz listede görünmüyorsa, yeni bir kurs ekleme seçeneğiniz olacaktir."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs ara..."])}
  }
}