export default {
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuжi"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavanje korisničkih podataka"])},
    "adjust_competency_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podešavanje nivoa kompetencije"])},
    "custom_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prilagođeni izveštaji"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursevi"])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okvir kompetencije"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditni sistem obuke"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidat"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatražite skrojeni kurs"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buduće funkcije"])},
    "leave_a_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostavite predlog"])}
  },
  "cmHome": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuжi"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaži mapu zemlje"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uskoro stižem"])},
    "learners_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovani učenici"])},
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učenici"])},
    "participants_courses_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status kurseva polaznika"])},
    "tailored_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrojeni kursevi "])},
    "real_time_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učesnici obuke u realnom vremenu"])},
    "workshop_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učesnici radionice"])},
    "online_course_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online polaznici kursa"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditni sistem obuke"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovršeno"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U toku"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatražite skrojeni kurs"])},
    "average_number_of_courses_per_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosečan broj kurseva po polaznicima"])},
    "compentencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovlašćenja"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberite"])},
    "select_a_position_to_see_competencies_related_to this_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberite poziciju da biste videli kompetencije vezane za ovu ulogu"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberi poziciju"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberi nivo"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbor regiona"])},
    "select_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberite kompetenciju"])},
    "assign_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodeli nivo"])},
    "learners_in_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno učenici: "])},
    "learners_with_this_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učenici sa ovom pozicijom: "])},
    "overview_of_required_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled potrebnih kompetencija"])},
    "select_a_position_above_to_see_the_competency_levels_of_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberite gorenavedeni položaj da biste videli nivoe kompetentnosti učenika na toj poziciji."])},
    "competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji o kompetenciji"])},
    "learn_more_about_the_competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saznajte više o okviru kompetencije"])},
    "select_a_position_to_see_competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberite gorenavedeni položaj da biste videli detalje o kompetencijama za tu poziciju."])},
    "number_of_learners_per_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj učenika po kompetencijama"])},
    "click_to_view_more_details_chart_on_the_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite na kompetenciju da biste videli više detalja. Grafikon sa desne strane."])},
    "you_are_seeing_data_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidite podatke za"])},
    "Epidemiology_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiologija je proučavanje faktora koji određuju i utiču na učestalost i distribuciju bolesti ili drugih događaja vezanih za zdravlje i njihove uzroke u definisanoj populaciji u cilju kontrole njihovog razvoja i širenja i uspostavljanja programa za sprečavanje razvoja i širenja."])},
    "TADS_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TADS su bolesti koje su veoma zarazne ili prenosive i imaju potencijal za veoma brzo širenje, bez obzira na nacionalne granice. Profilisanje bolesti se odnosi na Kliničke znake, etiologiju, patogenezu, klinički kurs, prenosni potencijal, epidemiologiju bolesti. Kontrola i prevencija nalaze se u nadležnosti kontrole i prevencije bolesti. MEĐU TAD-ovima su afrička svinjska groznica, visoko patogeni ptičiji grip, zarazna bovina pleuropneumonija, hemoragična septikaemija, grip A, rinderpest, groznica doline procepa , bliskoistočni respiratorni sindrom koronavirusa i bolesti stopala i usta."])}
  },
  "Epidemiology": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiologija je proučavanje faktora koji određuju i utiču na učestalost i distribuciju bolesti ili drugih događaja vezanih za zdravlje i njihove uzroke u definisanoj populaciji u cilju kontrole njihovog razvoja i širenja i uspostavljanja programa za sprečavanje razvoja i širenja."])}
  },
  "Transboundary Animal Diseases": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TADS su bolesti koje su veoma zarazne ili prenosive i imaju potencijal za veoma brzo širenje, bez obzira na nacionalne granice. Profilisanje bolesti se odnosi na Kliničke znake, etiologiju, patogenezu, klinički kurs, prenosni potencijal, epidemiologiju bolesti. Kontrola i prevencija nalaze se u nadležnosti kontrole i prevencije bolesti. MEĐU TAD-ovima su afrička svinjska groznica, visoko patogeni ptičiji grip, zarazna bovina pleuropneumonija, hemoragična septikaemija, grip A, rinderpest, groznica doline procepa , bliskoistočni respiratorni sindrom koronavirusa i bolesti stopala i usta."])}
  },
  "userData": {
    "single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedan korisnik"])},
    "bulk_user_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masovno otpremanje korisnika"])},
    "add_a_single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavanje jednog korisnika"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regiona"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcionalno"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izaberi poziciju"])},
    "csv_upload_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evo predloška za format . CSV otpremanje datoteke"])},
    "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi predložak"])},
    "cvs_confirmation_positions_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uverite se da su ove pozicije unete u vaš . CSV datoteka tačno onako kako se ovde pojavljuju. To su uloge sa priloženim nadležnostima za vašu zemlju."])},
    "upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otpremite svoj . CSV fajl ovde"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosleđivanje"])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podesi nivo"])},
    "click_to_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite da biste sačuvali rezultate"])}
  },
  "adjustCompetency": {
    "position_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozicione kompetencije "])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poziciju"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetencija"])},
    "level_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Potreban nivo"])},
    "view_and_adjust_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovde možete pogledati pozicije za vašu zemlju, potrebne nadležnosti ivele. Takođe možete podesiti potreban nivo."])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtriranje po položaju ili kompetencijama"])}
  },
  "learners": {
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uиim"])},
    "change_the_position_or_region_in_the_header_above_to_filter_the_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promena položaja ili regiona u zaglavlju iznad da biste filtrirali tabelu"])},
    "all_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svi učenici"])},
    "only_learners_with_competency_gaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samo učenici sa prazninama u kompetencijama"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puno ime"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regiona"])},
    "competencies_at_required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" nadležnosti na potrebnom nivou %"])},
    "enrol_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upisuj učenike"])},
    "mark_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označi kao dovršeno"])},
    "enrolled_course_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upisani % završetka kursa"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez regiona"])},
    "list_of_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista pozicija"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivoa"])},
    "no_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez nivoa"])},
    "awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svest"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
    "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetentan"])},
    "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vešt"])},
    "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspert"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavanje korisničkih podataka"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtriranje po položaju ili kompetencijama"])},
    "changing_competency_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promena nivoa kompetencije"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prilagođeni izveštaj"])},
    "select_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbor kategorije kursa"])},
    "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi izveštaj"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svi kursevi"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Moji kursevi"])},
    "search_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potražite kurs. Ako se vaš kurs ne pojavi na listi, imaćete mogućnost da dodate novi kurs."])},
    "course_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime kursa"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija"])},
    "shortname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kratko ime"])},
    "enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upisani kursevi"])},
    "date_enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum upisanog"])},
    "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dovršenja"])},
    "add_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj učenika"])},
    "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj datum"])},
    "add_a_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj novi kurs"])},
    "choose_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite učenika"])},
    "choose_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite kurs"])},
    "if_course_not_above_add_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako se vaš kurs ne pojavljuje iznad ili na listi svih kurseva, kliknite na dugme ispod da biste dodali novi kurs."])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okvir kompetencije"])},
    "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi ovaj dokument "])},
    "click_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite na kompetenciju da biste videli više informacija o tome, "])},
    "click_competency_framework_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ili da proverite detalje Okvira kompetencije."])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenutni nivo"])},
    "required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potreban nivo"])},
    "enrolled_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upisane kategorije"])},
    "add_current_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavanje trenutnog regiona"])},
    "enter_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovde unesite region"])}
  },
  "courseList": {
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potražite kurs. Ako se vaš kurs ne pojavi na listi, imaćete mogućnost da dodate novi kurs."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tražite kurs..."])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvi"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prethodne"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledeжi"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslednji"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Još uvek niste dodali nijedan kurs."])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stranica"])}
  },
  "singleLearner": {
    "competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovlašćenja"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursevi"])},
    "self_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoprocena"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredak"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "year_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godina je završena"])},
    "no_open_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemaљ otvorene kurseve."])},
    "no_closed_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemaš završene kurseve"])},
    "get_prepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripremi se"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponaљanje"])},
    "pcp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCP"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinku"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijaviti se"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaboravili ste lozinku?"])}
  },
  "selfAssessment": {
    "section_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 1"])},
    "section_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 2"])},
    "section_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 3"])},
    "section_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 4"])},
    "section_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 5"])},
    "section_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 6"])},
    "section_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 7"])},
    "section_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 8"])},
    "section_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKCIJA 9"])},
    "section_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 10"])},
    "section_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 11"])},
    "section_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 12"])},
    "section_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODELJAK 13"])},
    "epi_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam principa epidemiologije i njihove primene na opšte procedure kontrole bolesti (Standardni operativni postupci (SOP))."])},
    "epi_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam kako da sprovedem epidemiološku istragu u slučaju pojave bolesti koja se može prijaviti."])},
    "epi_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam epidemioloških pristupa koji se koriste tokom hitnih slučajeva životinja i njihovih uticaja (pojedinac protiv populacije)."])},
    "epi_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz odgovarajuće smernice, mogu da pomognem u prikupljanju epidemioloških podataka tokom epidemije prijavljive bolesti."])},
    "epi_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vodim epidemiološku istragu o sumnjivim/potvrđenim prostorijama, uključujući utvrđivanje puteva prenosa bolesti unutar prostorija."])},
    "epi_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da procenim kvalitet podataka o istrazi bolesti."])},
    "epi_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da utvrdim izvor izbijanja bolesti i obezbedim da bude podržana odgovarajućom epidemiološkom analizom."])},
    "epi_1_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da uradim analizu rizika."])},
    "epi_1_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da razvijem strateške planove reagovanja i predvidim verovatnoću postizanja ciljeva."])},
    "epi_1_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da vodim napredno planiranje da kontrolišem epidemiju i fazu oporavka."])},
    "tads_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imam znanje (vrste, kliničke znake itd.) o uobičajenim prekograničnim bolestima životinja (FMD, LSD, ASF, RVF) i alatima koji se koriste za njihovu prevenciju i kontrolu."])},
    "tads_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam gde da nađem autiиne informacije o distribuciji bolesti."])},
    "tads_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam zapaženih prekograničnih bolesti životinja i opisujem patogen, lezije, prenos, uzorkovanje i dijagnostičke metode kao i svaku potrebu za ličnom zaštitnom opremom."])},
    "tads_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam za nacionalne sisteme izveštavanja i znam koje su zapažene prekogranične bolesti životinja."])},
    "tads_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da obavim kliničke preglede pojedinačnih životinja i stdova za koje se sumnja da imaju prekograničnu bolest životinja, starosne lezije, da utvrdim koje životinje da uzorkuju i objasnim obrazloženje dijagnostičkih i terapijskih alata."])},
    "tads_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu samouvereno da dijagnostikujem sumnju na prekograničnu bolest životinja i da se javim po potrebi."])},
    "tads_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da dam odgovarajuće preporuke kada su klinički znaci zbunjujući."])},
    "tads_2_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam tereta prekograničnih bolesti životinja na nacionalnom nivou, uključujući i društveno-ekonomski uticaj."])},
    "disease_prev_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam principa prekogranične kontrole bolesti životinja i njihovih kontrolnih programa."])},
    "disease_prev_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svesna sam koje bolesti životinja zahtevaju obavezno obaveštavanje i značaj \"sistema za rano otkrivanje\", uključujući i to kako pristupiti relativnim informacijama o bolestima i kako reagovati na njih."])},
    "disease_prev_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U mogućnosti sam da učestvujem u planovima kontrole bolesti (npr. nadzor, kampanje vakcinacije, praćenje post vakcina, programi testiranja i kulta/lečenja)."])},
    "disease_prev_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da vodim planove za kontrolu bolesti, uključujući koordinaciju sa partnerima za njihovo efikasno sprovođenje."])},
    "disease_prev_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da nadgledam javno-privatna partnerstva na lokalnom nivou (uključujući vođenje tima, akreditaciju, reviziju)."])},
    "disease_prev_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U mogućnosti sam da vodim druge u sprovođenju programa u složenim situacijama i prilagođavam procedure na osnovu potreba odgovora."])},
    "disease_prev_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da razvijem javno-privatna partnerstva na lokalnom nivou."])},
    "disease_prev_3_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pišem programe kontrole bolesti na osnovu najboljih praksi i procenim njihovu isplatiost."])},
    "disease_prev_3_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da razvijam javno-privatna partnerstva na nacionalnom nivou."])},
    "bio_security_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam mera biosigurnosti na farmi i mogu da identifikujem sredine u kojima je potrebna biosigurnost."])},
    "bio_security_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam uticaja narušavanja biosigurnosti i relativnih mera ublažavanja."])},
    "bio_security_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da efikasno učestvujem u epidemiji bolesti kao član tima za bioekurnost, uključujući ulazak i ostavljanje prostorija na biosekuren način."])},
    "bio_security_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da prepoznam propuste u biosigurnosti i ispravim ga primenom odgovarajućih mera ublažavanja u situacijama niskog rizika."])},
    "bio_security_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da primenim protokole biosigurnosti specifične za patogene u rizičnim situacijama prenosa bolesti."])},
    "bio_security_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vodim tim za biosigurnost tokom epidemije."])},
    "bio_security_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da prilagodim procedure na osnovu potreba za odgovorom."])},
    "bio_security_4_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da razvijem i nadgledam planove biosigurnosti za industriju."])},
    "bio_security_4_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da procenim odluke o veterinarskom odgovoru i predvidim verovatnoću postizanja ciljeva."])},
    "bio_security_4_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da razvijem efikasne protokole biosigurnosti."])},
    "sampling_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam principa uzorkovanja, uključujući identifikovanje situacija koje zahtevaju uzorkovanje i implikacije uzorkovanja."])},
    "sampling_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam gde da nađem pouzdane informacije o uzorkovanja planova i tehnika."])},
    "sampling_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam da sam probao propise, programe i zoonotički potencijal životinjskih bolesti."])},
    "sampling_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prikupim odgovarajuće uzorke i spakujem ih (dok im to ne odgovara)."])},
    "sampling_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vam dam savet o prikupljanju uzoraka za dijagnostičko testiranje, tumačenje rezultata, diskutovanje o faktorima koji utiču na ishode testa, opisuje mehanizme dijagnotičkih testova, definišem dijagnostičke testove, njihove performanse i ograničenja."])},
    "sampling_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da treniram druge u odgovarajućim tehnikama uzorkovanja."])},
    "sampling_5_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da razvijem efikasne planove uzorkovanja s obzirom na karakteristike bolesti i situaciju sa uzorkom."])},
    "animal_id_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam metoda koje se koriste za identifikaciju životinja i kontrolu životinja, životinjskih proizvoda, opreme i ljudi, uključujući kontrolne zone."])},
    "animal_id_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam obrazaca kretanja industrije životinja, proizvoda i proizvoda."])},
    "animal_id_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu efikasno da kontrolišem kretanje životinja u prostorijama osumnjičenih/zaraženih i da budem član kontrolnog tima tokom epidemije."])},
    "animal_id_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da primenim identifikacione metode na životinje (npr. oznake za uši i tetovaže) i da koristim bazu podataka ličnih dokumenata za praćenje istorije kretanja životinja."])},
    "animal_id_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da izdam sertifikate za kretanje životinja u mirnodopsko vreme."])},
    "animal_id_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vodim tim da sprovodi kontrolne zone tokom epidemije i kontroliše kretanje životinja u kontrolnoj zoni i van njih."])},
    "animal_id_6_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da odredim granice kontrolne zone, izmenim je po potrebi i utvrdim da li se situacija kvalifikuje za jedinstveno izuzeće."])},
    "animal_id_6_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da treniram druge na kontroli kretanja."])},
    "animal_id_6_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da osmislim odgovarajuće smernice za kontrolu pokreta i podržim njihovo poboljšanje praćenjem rezultata programa."])},
    "animal_id_6_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu tačno da predvidim verovatnoću postizanja ciljeva i predvidim uticaje veterinarskih odluka."])},
    "emergency_disaster_mgt_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam zakona koji reguliše reagovanje u vanrednim situacijama, ulogu veterinara u vanrednim situacijama i koordinaciju između vladinih i nevladinih organizacija, vlasti i javnih i privatnih subjekata."])},
    "emergency_disaster_mgt_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pod pristiglim vođstvom mogu da učestvujem u hitnom odgovoru."])},
    "emergency_disaster_mgt_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da primenim koncept mehanizama koordinacije incidenata, njihov funkcionalni kapacitet i ulogu veterinara, tokom incidenta."])},
    "emergency_disaster_mgt_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da usmerim druge tokom hitnih sluиaja."])},
    "emergency_disaster_mgt_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu efikasno da komuniciram krize na lokalnom nivou."])},
    "emergency_disaster_mgt_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da koordiniram složene incidente i identifikujem različite sektore koji su umešani."])},
    "emergency_disaster_mgt_7_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da režiram kompleksan incident na lokalnom nivou."])},
    "emergency_disaster_mgt_7_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da komuniciram krize na regionalnom nivou."])},
    "emergency_disaster_mgt_7_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da usmerim nacionalne hitne reakcije i kriznu komunikaciju na nacionalnom nivou."])},
    "emergency_disaster_mgt_7_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prepoznam političku osetljivost i pronađem rešenja."])},
    "emergency_prep_8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam uloge planiranja spremnosti za hitne slučajeve za prioritetne bolesti i bilo kakvih relativnih planova pripremljenosti."])},
    "emergency_prep_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam vrednost približavanja pripremljenosti iz sve opasnosti-perspektive."])},
    "emergency_prep_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da koristim planove pripremljenosti i svestan sam relativnih propisa i standardnih operativnih procedura (SOP)."])},
    "emergency_prep_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu samouvereno da učestvujem u pripremljenosti na lokalnom nivou."])},
    "emergency_prep_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da razvijem specifičan plan za hitne slučajeve (uključujući i prikupljanje uzoraka) i da ga vežbam."])},
    "emergency_prep_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prepoznam sumnjive upada u bolesti i faktore koji utiču na sposobnost reagovanja."])},
    "emergency_prep_8_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da podržim dizajn i vođenje vežbi simulacije i treninga."])},
    "emergency_prep_8_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pišem operativne priručnike i industriju podrške u razvoju njihovog poslovnog kontinuiteta i planova reagovanja."])},
    "emergency_prep_8_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pišem i ažuriram nacionalne planove za kontrolu bolesti."])},
    "emergency_prep_8_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da razvijem alatke za reviziju/SOP-ove za verifikaciju aktiviranih planova."])},
    "emergency_res_9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam aktivnosti koje bi trebalo uključiti u reagovanje na incident."])},
    "emergency_res_9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam gde da pronađem dokumente smernica/Standardne operativne procedure (SOP)."])},
    "emergency_res_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da se pripremim i pomognem u istrazi epidemije."])},
    "emergency_res_9_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pod nadzorom mogu da koristim potrebnu opremu i obavljam tehničke veštine tokom epidemije (metode odlaganja, vršenje procene, čišćenje i dezinfekciju, kontrole divljih životinja)."])},
    "emergency_res_9_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vodim repondere tokom epidemije."])},
    "emergency_res_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da obavljam aktivnosti vezane za sprovođenje zakona (očuvam scenu, starateljstvo nad dokazima itd)."])},
    "emergency_res_9_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da obučim druge u hitnim slučajevima."])},
    "emergency_res_9_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da procenim ishode, procenim posledice prethodnih promena i preporučim strategije reagovanja."])},
    "emergency_res_9_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da odobrim preporuke i osmislim strategije odgovora."])},
    "emergency_res_9_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da definišem odgovarajuće ishode i predvidim verovatnoću postizanja ciljeva odgovora."])},
    "vet_prod_10_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da savetujem i administrirate veterinarske proizvode za lečenje uobičajenih bolesti životinja."])},
    "vet_prod_10_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da primenim regulatorne i administrativne kontrole nad antimikrobnom otpornošću (AMR), antimikrobnom upotrebom (AMU) i učestvujem u njihovom nadzoru."])},
    "vet_prod_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prepišem veterinarske proizvode."])},
    "vet_prod_10_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pronađem i protumačim informacije u vezi sa vezom između upotrebe antimikrobnih lekova u hrani životinja i razvoja AMR-a u patogenima od ljudskog značaja."])},
    "vet_prod_10_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam regulatornih i administrativnih kontrola za uvoz, proizvodnju i registraciju veterinarske biologije."])},
    "vet_prod_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da sprovedem AMR nadzor."])},
    "vet_prod_10_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imam neophodne veštine da odobrim nove veterinarske proizvode."])},
    "vet_prod_10_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da podržim dizajn AMR programa za nadzor."])},
    "vet_prod_10_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da osmislim AMR programe nadzora i politike za regulisanje veterinarskih lekova i biologije."])},
    "vet_prod_10_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prepoznam implikacije hitnih slučajeva na prekide lanca snabdevanja i međunarodna ograničenja kretanja bioloških proizvoda."])},
    "animal_welfare_11_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da identifikujem propise o dobrobiti životinja/standarde i opišem odgovornost (vlasnici, veterinari, rukovaoci)."])},
    "animal_welfare_11_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da koristim tehnike rukovanja životinjama i opremu na način koji štiti dobrobit životinja prepoznajući znake bola i tegoba."])},
    "animal_welfare_11_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da identifikujem i prijavim probleme sa dobrobiti životinja, i učestvujem u korektivnim akcijama."])},
    "animal_welfare_11_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da izvršim humanu eutanaziju na pojedinačnim životinjama, uključujući procenu zapanjujućeg/krvarenja i primenu metoda uzdržavanja."])},
    "animal_welfare_11_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vodim tim u masovnoj depopulaciji životinja prateći protokole zasnovane na humanim metodama uništenja."])},
    "animal_welfare_11_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da rezimiram socijalne implikacije različitih kontrolnih metoda (npr. kontrola kretanja životinja)."])},
    "animal_welfare_11_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U mogućnosti sam da vodim druge u sprovođenju programa dobrobiti životinja u složenim situacijama i prilagodim procedure na osnovu potreba za odgovorom."])},
    "animal_welfare_11_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da izaberem odgovarajuće humane metode ubijanja za masovnu depopulaciju na osnovu konkretne situacije."])},
    "animal_welfare_11_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pišem programe usaglašenosti koristeći naučnu literaturu, politiku i smernice."])},
    "animal_welfare_11_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu tačno da predvidim društveno prihvatanje akcija odgovora i predvidim uticaje veterinarskih odluka."])},
    "risk_12_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam za koncepte analize rizika: identifikacija opasnosti, procena rizika, brza procena rizika, upravljanje rizicima, komunikacija sa rizikom."])},
    "risk_12_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam kako se analiza rizika može koristiti da bi se osiguralo da zdravlje životinja, ljudi i životne sredine bude zaštićeno u katastrofalnim situacijama."])},
    "risk_12_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pronađem informacije potrebne za analizu rizika."])},
    "risk_12_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znam kako se analiza rizika može primeniti na procenu rizika od planiranja/ublažavanja/pripreme bolesti životinja i oporavka, zaostaka veterinarskih lekova, uključujući uvoz životinja i životinjskih proizvoda."])},
    "risk_12_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da koristim osnovne/ključne koncepte i principe analize rizika u skladu sa propisanom politikom i procedurama/smernicama."])},
    "risk_12_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da mapiram lokalne lance vrednosti i prenesem rizike prenosa bolesti."])},
    "risk_12_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da primenim principe analize rizika u neobičnim/složenim situacijama i podržim donošenje odluka tokom složenih situacija."])},
    "risk_12_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da identifikujem i upravljam komunikacionim planovima vezanim za rizike prenosa bolesti."])},
    "risk_12_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da sprovedem analizu rizika na nivou organizacije."])},
    "risk_12_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da podržim odluke o politici upravljanja rizicima i strategije komunikacije rizika."])},
    "safety_13_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam radnog prostora i opasnosti po zdravlje i principa bezbednosti životinja, ljudi i životne sredine u vanrednim situacijama."])},
    "safety_13_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svestan sam posledica vanrednih situacija i upravljanja katastrofama na život."])},
    "safety_13_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da identifikujem izvore podrške (lične i organizacione)."])},
    "safety_13_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prepoznam radna okruženja koja nisu u skladu sa odgovarajućim zdravstvenim i bezbednosnim standardima."])},
    "safety_13_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da prepoznam znake pogoršanja blagostanja kod drugih (mentalna prva pomoć) i da intervenišem."])},
    "safety_13_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U stanju sam da preduzmem korektivne radnje kako bih ispravio uslove rada koji vode u nebezbedno radno okruženje."])},
    "safety_13_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da vodim istragu o nesreжama ili blizu promaљaja."])},
    "safety_13_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da dam preporuke da se smanji verovatnoća opasnosti po mentalno ili fizičko zdravlje."])},
    "safety_13_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogu da pišem/ažuriram smernice i procedure za zaštitu bezbednosti, zdravlja i dobrobiti zaposlenih."])},
    "rank_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangirajte nivo na kojem se slažete sa sledećim izjavama."])},
    "survey_sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeljci ankete"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli u TOM Samoprocenu"])},
    "welcome_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOM Samoprocena je alatka koja će vam pomoći da razumete trenutni nivo kompetentnosti u oblastima koje se prate u Okviru kompetencija za kontrolu bolesti. Svaki odeljak je dizajniran da bude završen za 10-15 minuta."])},
    "welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od vas će biti zatraženo da ocenite svoj nivo poverenja u svoje znanje ili sposobnost da obavljate zadatke koji predstavljaju različite nivoe stručnosti u 13 kompetencija okvira."])},
    "click_survey_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite na bilo koji od odeljaka ankete na bočnoj tabli da biste počeli."])},
    "thank_you_for_completing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvala vam što ste završili TOM Samoprocenu!"])},
    "assessment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosleđivanje"])},
    "section_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultati odeljka"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snažno se slažete"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne slažem se"])},
    "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije primenljivo"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upitnik za samoprocenu - okvir kompetencije kontrole bolesti"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivo"])}
  },
  "routes": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuжi"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavljivanje"])},
    "PasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PasswordReset"])},
    "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursevi"])},
    "Nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kandidat"])},
    "Request Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs zahteva"])},
    "Training Credit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditni sistem obuke"])},
    "Suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predloge"])},
    "Future Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buduće funkcije"])},
    "Single Learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednoumni učenik"])},
    "Competency Framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okvir kompetencije"])},
    "Custom Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prilagođeni izveštaj"])},
    "Add User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodavanje korisničkih podataka"])},
    "Adjust Levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podesi nivoe"])},
    "admin_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavljivanje administratora"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratorskog"])},
    "Learner Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil učenika"])}
  },
  "positions": {
    "central_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralni veterinar"])},
    "regional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regionalni veterinar"])}
  },
  "competencies": {
    "epidemiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiologija"])},
    "tads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekogranične bolesti životinja"])},
    "disease_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programi prevencije i kontrole bolesti"])},
    "biosecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biosigurnost"])},
    "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzorkovanje"])},
    "animal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola identifikacije i kretanja životinja"])},
    "emergency_disaster_mgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje vanrednim situacijama i katastrofama"])},
    "emergency_prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripremljenost za hitne slučajeve"])},
    "emergency_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitna reakcija"])},
    "vet_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veterinarski proizvodi"])},
    "animal_welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrobit životinja"])},
    "risk_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primena analize rizika"])},
    "safety_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezbednost, zdravlje i dobrobit"])}
  },
  "compLevels": {
    "Awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svest"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
    "Competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompetentan"])},
    "Proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vešt"])},
    "Expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspert"])}
  },
  "certificates": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
    "date_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum otpremanja"])},
    "no_certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema certifikata za prikazivanje"])},
    "drag_n_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevucite i otpustite pdf fajlove ovde ili kliknite da biste otpremili"])},
    "file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimalna veličina datoteke: 2 MB"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prethodne"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledeжi"])},
    "certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikate"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otpremanje novog certifikata"])}
  },
  "externalCourses": {
    "external_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoljni kursevi"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacija"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt osoba"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezu"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
  }
}