export default {
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi dati utente"])},
    "adjust_competency_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regolare i livelli di competenza"])},
    "custom_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporti personalizzati"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi"])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadro delle competenze"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema di crediti formativi"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominato"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi un corso su misura"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche future"])},
    "leave_a_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia un suggerimento"])}
  },
  "cmHome": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza la mappa del paese"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimamente"])},
    "learners_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenti registrati"])},
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenti"])},
    "participants_courses_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato dei corsi dei partecipanti"])},
    "tailored_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi su misura"])},
    "real_time_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti alla formazione in tempo reale"])},
    "workshop_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti al workshop"])},
    "online_course_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti al corso online"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema di crediti formativi"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi un corso su misura"])},
    "average_number_of_courses_per_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio di corsi per partecipante"])},
    "compentencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenze"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
    "select_a_position_to_see_competencies_related_to this_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare una posizione per vedere le competenze relative a questo ruolo"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la posizione"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare il livello"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la regione"])},
    "select_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la competenza"])},
    "assign_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione del livello"])},
    "learners_in_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenti in totale: "])},
    "learners_with_this_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenti con questa posizione: "])},
    "overview_of_required_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica delle competenze richieste"])},
    "select_a_position_above_to_see_the_competency_levels_of_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare una posizione in alto per vedere i livelli di competenza dei discenti in quella posizione."])},
    "competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli sulla competenza"])},
    "learn_more_about_the_competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per saperne di più sul quadro delle competenze"])},
    "select_a_position_to_see_competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionate una posizione in alto per vedere i dettagli delle competenze per quella posizione."])},
    "number_of_learners_per_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di studenti per competenza"])},
    "click_to_view_more_details_chart_on_the_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic su una competenza per visualizzare ulteriori dettagli. Grafico a destra."])},
    "you_are_seeing_data_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengono visualizzati i dati per"])},
    "Epidemiology_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'epidemiologia è lo studio dei fattori che determinano e influenzano la frequenza e la distribuzione delle malattie o di altri eventi correlati alla salute e le loro cause in una popolazione definita, allo scopo di controllarne lo sviluppo e la diffusione e di stabilire programmi per prevenire lo sviluppo e la diffusione."])},
    "TADS_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le TADS sono malattie altamente contagiose o trasmissibili e con un potenziale di diffusione molto rapido, indipendentemente dai confini nazionali. Il profilo della malattia si riferisce ai segni clinici, all'eziologia, alla patogenesi, al decorso clinico, al potenziale di trasmissione e all'epidemiologia della malattia. Il controllo e la prevenzione rientrano nelle competenze del controllo e della prevenzione delle malattie. Le DAT comprendono la peste suina africana, l'influenza aviaria ad alta patogenicità, la pleuropolmonite contagiosa bovina, la setticemia emorragica, l'influenza A, la peste bovina, la febbre della valle del fiume, il coronavirus della sindrome respiratoria del Medio Oriente e l'afta epizootica."])}
  },
  "Epidemiology": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'epidemiologia è lo studio dei fattori che determinano e influenzano la frequenza e la distribuzione delle malattie o di altri eventi correlati alla salute e le loro cause in una popolazione definita, allo scopo di controllarne lo sviluppo e la diffusione e di stabilire programmi per prevenire lo sviluppo e la diffusione."])}
  },
  "Transboundary Animal Diseases": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le TADS sono malattie altamente contagiose o trasmissibili e con un potenziale di diffusione molto rapido, indipendentemente dai confini nazionali. Il profilo della malattia si riferisce ai segni clinici, all'eziologia, alla patogenesi, al decorso clinico, al potenziale di trasmissione e all'epidemiologia della malattia. Il controllo e la prevenzione rientrano nelle competenze del controllo e della prevenzione delle malattie. Le DAT comprendono la peste suina africana, l'influenza aviaria ad alta patogenicità, la pleuropolmonite contagiosa bovina, la setticemia emorragica, l'influenza A, la peste bovina, la febbre della valle del fiume, il coronavirus della sindrome respiratoria del Medio Oriente e l'afta epizootica."])}
  },
  "userData": {
    "single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente singolo"])},
    "bulk_user_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento massivo degli utenti"])},
    "add_a_single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un singolo utente"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzionale"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la posizione"])},
    "csv_upload_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecco un modello per il formato del file di caricamento .CSV"])},
    "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il modello"])},
    "cvs_confirmation_positions_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicuratevi che queste posizioni siano inserite nel vostro file .CSV esattamente come appaiono qui. Questi sono i ruoli con le relative competenze per il vostro Paese."])},
    "upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricare il file .CSV qui"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regolazione del livello"])},
    "click_to_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic su Salva risultati"])}
  },
  "adjustCompetency": {
    "position_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenze della posizione "])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenza"])},
    "level_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Livello richiesto"])},
    "view_and_adjust_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui è possibile visualizzare le posizioni per il proprio Paese, le competenze e i livelli richiesti. È inoltre possibile regolare il livello richiesto."])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrare per posizione o competenza"])}
  },
  "learners": {
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendista"])},
    "change_the_position_or_region_in_the_header_above_to_filter_the_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare la posizione o la regione nell'intestazione in alto per filtrare la tabella"])},
    "all_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli studenti"])},
    "only_learners_with_competency_gaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo gli studenti con lacune nelle competenze"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome e cognome"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regione"])},
    "competencies_at_required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" competenze al livello richiesto %"])},
    "enrol_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscrivere gli studenti"])},
    "mark_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segna Completa"])},
    "enrolled_course_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iscritti completamento corso %"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna regione"])},
    "list_of_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle posizioni"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livelli"])},
    "no_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun livello"])},
    "awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consapevolezza"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principiante"])},
    "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competente"])},
    "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competente"])},
    "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperto"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi dati utente"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrare per posizione o competenza"])},
    "changing_competency_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare il livello di competenza di"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto personalizzato"])},
    "select_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la categoria del corso"])},
    "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il rapporto"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corso"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corso"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i corsi"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I miei corsi"])},
    "search_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercare il proprio corso. Se il corso non compare nell'elenco, è possibile aggiungere un nuovo corso."])},
    "course_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del corso"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "shortname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome breve"])},
    "enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corsi iscritti"])},
    "date_enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di iscrizione"])},
    "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di completamento"])},
    "add_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi allievo"])},
    "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi data"])},
    "add_a_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un nuovo corso"])},
    "choose_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere l'allievo"])},
    "choose_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere il corso"])},
    "if_course_not_above_add_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il vostro corso non appare in alto o nell'elenco di tutti i corsi, fate clic sul pulsante sottostante per aggiungere un nuovo corso."])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadro delle competenze"])},
    "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica questo documento "])},
    "click_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic su una competenza per visualizzare ulteriori informazioni su di essa, "])},
    "click_competency_framework_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o per controllare i dettagli del Quadro delle competenze."])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello attuale"])},
    "required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello richiesto"])},
    "enrolled_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie iscritte"])},
    "add_current_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungete la vostra regione attuale"])},
    "enter_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire qui la regione"])}
  },
  "courseList": {
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercare il proprio corso. Se il corso non compare nell'elenco, è possibile aggiungere un nuovo corso."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cercare un corso..."])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora aggiunto alcun corso."])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina"])}
  },
  "singleLearner": {
    "competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competenze"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi"])},
    "self_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autovalutazione"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progressi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "year_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno completato"])},
    "no_open_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non avete corsi aperti."])},
    "no_closed_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non avete corsi completati"])},
    "get_prepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatevi"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamento"])},
    "pcp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCP"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])}
  },
  "selfAssessment": {
    "section_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 1"])},
    "section_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 2"])},
    "section_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 3"])},
    "section_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 4"])},
    "section_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 5"])},
    "section_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 6"])},
    "section_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 7"])},
    "section_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 8"])},
    "section_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 9"])},
    "section_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 10"])},
    "section_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 11"])},
    "section_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 12"])},
    "section_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEZIONE 13"])},
    "epi_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza dei principi dell'epidemiologia e della loro applicazione alle procedure generali di controllo delle malattie (Procedure Operative Standard (SOP))."])},
    "epi_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So come condurre un'indagine epidemiologica nel caso in cui si verifichi una malattia soggetta a denuncia."])},
    "epi_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conosco gli approcci epidemiologici utilizzati durante le emergenze animali e il loro impatto (individuale o di popolazione)."])},
    "epi_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con una guida appropriata, posso assistere nella raccolta di dati epidemiologici durante un'epidemia di una malattia segnalabile."])},
    "epi_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di condurre un'indagine epidemiologica su un locale sospetto/confermato, compresa la determinazione delle vie di trasmissione della malattia all'interno del locale."])},
    "epi_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di valutare la qualità dei dati relativi alle indagini sulle malattie."])},
    "epi_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di determinare l'origine di un focolaio di malattia e di assicurarmi che sia supportato da un'analisi epidemiologica appropriata."])},
    "epi_1_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso condurre un'analisi dei rischi."])},
    "epi_1_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare piani di risposta strategici e di prevedere la probabilità di raggiungere gli obiettivi."])},
    "epi_1_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di condurre una pianificazione avanzata per controllare un'epidemia e la fase di recupero."])},
    "tads_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho conoscenze (specie, segni clinici, ecc.) sulle comuni malattie animali transfrontaliere (FMD, LSD, ASF, RVF) e sugli strumenti utilizzati per la loro prevenzione e controllo."])},
    "tads_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So dove trovare informazioni aggiornate sulla distribuzione delle malattie."])},
    "tads_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza delle malattie animali transfrontaliere soggette a obbligo di notifica e descrivo l'agente patogeno, le lesioni, la trasmissione, i metodi di campionamento e di diagnosi, nonché l'eventuale necessità di dispositivi di protezione individuale."])},
    "tads_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conosco i sistemi di segnalazione nazionali e so quali sono le malattie animali transfrontaliere soggette a notifica."])},
    "tads_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di eseguire esami clinici di singoli animali e di mandrie sospettate di avere una malattia animale transfrontaliera, di valutare l'età delle lesioni, di determinare quali animali prelevare e di spiegare il razionale degli strumenti diagnostici e terapeutici."])},
    "tads_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di diagnosticare con sicurezza il sospetto di una malattia animale transfrontaliera e di segnalarla come richiesto."])},
    "tads_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di fornire raccomandazioni appropriate quando i segni clinici sono confusi."])},
    "tads_2_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole dell'onere delle malattie animali transfrontaliere a livello nazionale, compreso l'impatto socio-economico."])},
    "disease_prev_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza dei principi del controllo delle malattie animali transfrontaliere e dei relativi programmi di controllo."])},
    "disease_prev_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza di quali malattie animali richiedono la notifica obbligatoria e dell'importanza del \"sistema di rilevamento precoce\", compreso il modo in cui accedere alle informazioni relative alle malattie e come reagire ad esse."])},
    "disease_prev_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di partecipare ai piani di controllo della malattia (ad esempio, sorveglianza, campagne di vaccinazione, monitoraggio post vaccinale, programmi di test e abbattimento/trattamento)."])},
    "disease_prev_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di condurre piani di controllo delle malattie, compreso il coordinamento con i partner per la loro efficiente attuazione."])},
    "disease_prev_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di supervisionare i partenariati pubblico-privati a livello locale (compresa la guida di un team, l'accreditamento, l'audit)."])},
    "disease_prev_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di guidare gli altri nell'attuazione di programmi in situazioni complesse e di adattare le procedure in base alle esigenze di risposta."])},
    "disease_prev_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare partenariati pubblico-privati a livello locale."])},
    "disease_prev_3_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di redigere programmi di controllo delle malattie basati sulle migliori pratiche e di valutarne il rapporto costo-efficacia."])},
    "disease_prev_3_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare partenariati pubblico-privati a livello nazionale."])},
    "bio_security_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza delle misure di biosicurezza in azienda e so identificare gli ambienti in cui è necessaria la biosicurezza."])},
    "bio_security_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole dell'impatto delle violazioni della biosicurezza e delle relative misure di mitigazione."])},
    "bio_security_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di partecipare efficacemente a un focolaio di malattia come membro di una squadra di bioprotezione, compreso l'ingresso e l'uscita dai locali in modo biosicuro."])},
    "bio_security_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di riconoscere le violazioni della biosicurezza e di porvi rimedio applicando le misure di mitigazione appropriate in situazioni di basso rischio."])},
    "bio_security_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di applicare protocolli di biosicurezza specifici per i patogeni in situazioni di alto rischio di trasferimento della malattia."])},
    "bio_security_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di guidare una squadra di biosicurezza durante un'epidemia."])},
    "bio_security_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di adattare le procedure in base alle esigenze di risposta."])},
    "bio_security_4_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare e verificare piani di biosicurezza per l'industria."])},
    "bio_security_4_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di valutare le decisioni di risposta veterinaria e di prevedere la probabilità di raggiungere gli obiettivi."])},
    "bio_security_4_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare protocolli di biosicurezza efficaci."])},
    "sampling_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole dei principi del campionamento, compresa l'identificazione delle situazioni che richiedono il campionamento e le implicazioni del campionamento."])},
    "sampling_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So dove trovare informazioni affidabili sui piani e sulle tecniche di campionamento."])},
    "sampling_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza delle normative, dei programmi di campionamento e del potenziale zoonotico delle malattie animali."])},
    "sampling_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso raccogliere campioni appropriati e confezionarli (garantendone l'integrità)."])},
    "sampling_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso fornire consulenza sulla raccolta di campioni per i test diagnostici, interpretare i risultati, discutere i fattori che influenzano i risultati dei test, descrivere i meccanismi dei test diagnostici, definire i test diagnostici, le loro prestazioni e i loro limiti."])},
    "sampling_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di addestrare gli altri alle tecniche di campionamento appropriate."])},
    "sampling_5_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare piani di campionamento efficaci in base alle caratteristiche della malattia e della situazione di campionamento."])},
    "animal_id_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza dei metodi utilizzati per l'identificazione degli animali e per il controllo di animali, prodotti di origine animale, attrezzature e persone, comprese le zone di controllo."])},
    "animal_id_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza dei modelli di spostamento degli animali, dei prodotti e dei sottoprodotti dell'industria."])},
    "animal_id_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di controllare efficacemente gli spostamenti degli animali da un locale sospetto/infetto e di far parte di una squadra di controllo durante un'epidemia."])},
    "animal_id_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di applicare metodi di identificazione agli animali (ad esempio marche auricolari e tatuaggi) e di utilizzare un database di identificazione per tracciare la storia degli spostamenti degli animali."])},
    "animal_id_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso rilasciare certificati per il movimento degli animali in tempo di pace."])},
    "animal_id_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di guidare una squadra per far rispettare le zone di controllo durante un'epidemia e controllare i movimenti degli animali dentro e fuori una zona di controllo."])},
    "animal_id_6_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di determinare i limiti di una zona di controllo, di modificarla come richiesto e di determinare se una situazione si qualifica per un'esenzione unica."])},
    "animal_id_6_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso addestrare gli altri al controllo dei movimenti."])},
    "animal_id_6_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di progettare adeguate politiche di controllo del movimento e di sostenerne il miglioramento monitorando i risultati del programma."])},
    "animal_id_6_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di prevedere con precisione la probabilità di raggiungere gli obiettivi e di prevedere l'impatto delle decisioni veterinarie."])},
    "emergency_disaster_mgt_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza della legislazione che regola la risposta alle emergenze, il ruolo dei veterinari negli eventi di emergenza e il coordinamento tra i soccorritori governativi e non governativi, le autorità e gli enti pubblici e privati."])},
    "emergency_disaster_mgt_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotto una guida appropriata, posso partecipare a un intervento di emergenza."])},
    "emergency_disaster_mgt_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di applicare il concetto di meccanismi di coordinamento dell'incidente, la loro capacità funzionale e il ruolo dei veterinari, durante un incidente."])},
    "emergency_disaster_mgt_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di dirigere gli altri durante gli interventi di emergenza."])},
    "emergency_disaster_mgt_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di comunicare efficacemente le crisi a livello locale."])},
    "emergency_disaster_mgt_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di coordinare incidenti complessi e di identificare i diversi settori coinvolti."])},
    "emergency_disaster_mgt_7_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso dirigere un incidente complesso a livello locale."])},
    "emergency_disaster_mgt_7_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di comunicare le crisi a livello regionale."])},
    "emergency_disaster_mgt_7_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di dirigere le risposte alle emergenze nazionali e la comunicazione delle crisi a livello nazionale."])},
    "emergency_disaster_mgt_7_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So riconoscere le sensibilità politiche e trovare soluzioni."])},
    "emergency_prep_8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole del ruolo della pianificazione della preparazione all'emergenza per le malattie prioritarie e dei relativi piani di preparazione."])},
    "emergency_prep_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conosco il valore di affrontare la preparazione da una prospettiva di tutti i rischi."])},
    "emergency_prep_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di utilizzare i piani di preparazione e sono a conoscenza dei relativi regolamenti e delle procedure operative standard (SOP)."])},
    "emergency_prep_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso partecipare con fiducia alla preparazione a livello locale."])},
    "emergency_prep_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare un piano di emergenza specifico per il sito (compresa la raccolta di campioni) e di esercitarlo."])},
    "emergency_prep_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di riconoscere le sospette incursioni di malattie e i fattori che influenzano la capacità di risposta."])},
    "emergency_prep_8_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso fornire assistenza nella progettazione e nell'esecuzione di esercizi di simulazione e formazione."])},
    "emergency_prep_8_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso scrivere manuali operativi e supportare l'industria nello sviluppo dei loro piani di continuità operativa e di risposta."])},
    "emergency_prep_8_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di scrivere e aggiornare piani nazionali per il controllo delle malattie."])},
    "emergency_prep_8_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di sviluppare strumenti di audit/SOP per la verifica dei piani attivati."])},
    "emergency_res_9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole delle attività che dovrebbero essere incluse in una risposta agli incidenti."])},
    "emergency_res_9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So dove trovare i documenti di orientamento e le procedure operative standard (SOP)."])},
    "emergency_res_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di preparare e assistere un'indagine su un focolaio."])},
    "emergency_res_9_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotto supervisione, sono in grado di utilizzare le attrezzature richieste e di eseguire le competenze tecniche durante un'epidemia (metodi di smaltimento, esecuzione di valutazioni, pulizia e disinfezione, controlli della fauna selvatica)."])},
    "emergency_res_9_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso guidare i risponditori durante un'epidemia."])},
    "emergency_res_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di svolgere attività legate alle forze dell'ordine (preservare la scena, custodire le prove, ecc.)."])},
    "emergency_res_9_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di addestrare gli altri alla risposta alle emergenze."])},
    "emergency_res_9_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di valutare i risultati, le conseguenze dei cambiamenti precedenti e di raccomandare strategie di risposta."])},
    "emergency_res_9_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso approvare le raccomandazioni e progettare strategie di risposta."])},
    "emergency_res_9_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di definire i risultati appropriati e di prevedere la probabilità di raggiungere gli obiettivi di risposta."])},
    "vet_prod_10_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di consigliare e somministrare prodotti veterinari per il trattamento delle più comuni malattie degli animali."])},
    "vet_prod_10_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di applicare i controlli normativi e amministrativi sulla resistenza agli antimicrobici (AMR) e sull'uso degli antimicrobici (AMU) e di partecipare alla loro sorveglianza."])},
    "vet_prod_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso prescrivere prodotti veterinari."])},
    "vet_prod_10_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di trovare e interpretare informazioni sul legame tra l'uso di antimicrobici negli animali da reddito e lo sviluppo della resistenza antimicrobica in patogeni di importanza umana."])},
    "vet_prod_10_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono a conoscenza dei controlli normativi e amministrativi per l'importazione, la produzione e la registrazione dei biologici veterinari."])},
    "vet_prod_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di implementare la sorveglianza AMR."])},
    "vet_prod_10_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho le competenze necessarie per approvare nuovi prodotti veterinari."])},
    "vet_prod_10_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di supportare la progettazione di programmi di sorveglianza della resistenza antimicrobica."])},
    "vet_prod_10_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di progettare programmi di sorveglianza della resistenza antimicrobica e politiche di regolamentazione dei farmaci veterinari e dei prodotti biologici."])},
    "vet_prod_10_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di riconoscere le implicazioni delle emergenze sulle interruzioni della catena di approvvigionamento e sulle restrizioni internazionali alla circolazione dei prodotti biologici."])},
    "animal_welfare_11_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di identificare le norme e gli standard relativi al benessere degli animali e di descrivere le responsabilità (proprietari, veterinari, operatori)."])},
    "animal_welfare_11_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di utilizzare le tecniche e le attrezzature di manipolazione degli animali in modo da tutelare il loro benessere, riconoscendo i segni di dolore e di sofferenza."])},
    "animal_welfare_11_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di identificare e segnalare i problemi di benessere degli animali e di partecipare alle azioni correttive."])},
    "animal_welfare_11_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di eseguire l'eutanasia umana su singoli animali, compresa la valutazione di stordimento/sanguinamento e l'applicazione di metodi di contenimento."])},
    "animal_welfare_11_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di guidare una squadra nello spopolamento di massa degli animali seguendo protocolli basati su metodi di distruzione umanitari."])},
    "animal_welfare_11_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di riassumere le implicazioni per il benessere dei vari metodi di controllo (ad esempio il controllo del movimento degli animali)."])},
    "animal_welfare_11_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di guidare gli altri nell'attuazione di programmi per il benessere degli animali in situazioni complesse e di adattare le procedure in base alle esigenze di risposta."])},
    "animal_welfare_11_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di selezionare i metodi di uccisione umana più adatti per lo spopolamento di massa in base alla situazione specifica."])},
    "animal_welfare_11_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di redigere programmi di conformità utilizzando la letteratura scientifica, le politiche e le linee guida."])},
    "animal_welfare_11_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di prevedere con precisione l'accettazione sociale delle azioni di risposta e di prevedere l'impatto delle decisioni veterinarie."])},
    "risk_12_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conosco i concetti di analisi del rischio: identificazione dei pericoli, valutazione del rischio, valutazione rapida del rischio, gestione del rischio, comunicazione del rischio."])},
    "risk_12_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So come l'analisi del rischio possa essere utilizzata per garantire la protezione della salute animale, umana e ambientale in situazioni di disastro."])},
    "risk_12_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di reperire le informazioni necessarie per effettuare un'analisi dei rischi."])},
    "risk_12_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So come l'analisi del rischio può essere applicata alla valutazione del rischio di pianificazione/mitigazione/preparazione delle malattie animali, risposta e recupero, residui di farmaci veterinari, compresa l'importazione di animali e prodotti animali."])},
    "risk_12_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di utilizzare i concetti e i principi di base/chiave dell'analisi del rischio in conformità con le politiche e le procedure/linee guida prescritte."])},
    "risk_12_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di mappare le catene del valore locali e di trasmettere i rischi di trasmissione delle malattie."])},
    "risk_12_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di applicare i principi dell'analisi del rischio in situazioni insolite/complesse e di supportare il processo decisionale in situazioni complesse."])},
    "risk_12_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di identificare e gestire i piani di comunicazione relativi ai rischi di trasmissione delle malattie."])},
    "risk_12_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di effettuare l'analisi dei rischi a livello di organizzazione."])},
    "risk_12_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di supportare le decisioni politiche di gestione del rischio e le strategie di comunicazione del rischio."])},
    "safety_13_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole dei rischi per l'ambiente di lavoro e la salute e dei principi di sicurezza animale, umana e ambientale in situazioni di emergenza."])},
    "safety_13_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono consapevole delle implicazioni della gestione delle emergenze e dei disastri sui mezzi di sussistenza."])},
    "safety_13_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di identificare le fonti di sostegno (personali e organizzative)."])},
    "safety_13_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di riconoscere gli ambienti di lavoro che NON rispettano gli standard di salute e sicurezza appropriati."])},
    "safety_13_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di riconoscere i segnali di deterioramento del benessere negli altri (primo soccorso mentale) e di intervenire."])},
    "safety_13_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di intraprendere azioni correttive per correggere le condizioni di lavoro che portano a un ambiente di lavoro non sicuro."])},
    "safety_13_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso condurre indagini su incidenti o quasi incidenti."])},
    "safety_13_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di formulare raccomandazioni per ridurre la probabilità di rischi per la salute mentale o fisica."])},
    "safety_13_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono in grado di scrivere/aggiornare politiche e procedure per proteggere la sicurezza, la salute e il benessere dei dipendenti."])},
    "rank_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di classificare il livello di accordo con le seguenti affermazioni."])},
    "survey_sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sezioni del sondaggio"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti nell'Autovalutazione TOM"])},
    "welcome_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autovalutazione TOM è uno strumento che aiuta a comprendere il proprio livello attuale di competenza nelle aree tracciate nel Quadro delle competenze per il controllo delle malattie. Ogni sezione è pensata per essere completata in 10-15 minuti."])},
    "welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi verrà chiesto di valutare il vostro livello di fiducia nella vostra conoscenza o capacità di eseguire compiti che rappresentano vari livelli di competenza nelle 13 competenze del framework."])},
    "click_survey_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliccate su una qualsiasi delle sezioni del sondaggio nel pannello laterale per iniziare."])},
    "thank_you_for_completing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per aver completato l'Autovalutazione TOM!"])},
    "assessment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "section_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi di sezione"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortemente d'accordo"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono d'accordo"])},
    "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicabile"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionario di autovalutazione - Quadro delle competenze per il controllo delle malattie"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello"])}
  },
  "routes": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso"])},
    "PasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PasswordReset"])},
    "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi"])},
    "Nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominato"])},
    "Request Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di corso"])},
    "Training Credit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema di crediti formativi"])},
    "Suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimenti"])},
    "Future Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche future"])},
    "Single Learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studente singolo"])},
    "Competency Framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadro delle competenze"])},
    "Custom Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto personalizzato"])},
    "Add User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi dati utente"])},
    "Adjust Levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regola i livelli"])},
    "admin_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso amministratore"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "Learner Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo dell'allievo"])}
  },
  "positions": {
    "central_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veterinario centrale"])},
    "regional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veterinario regionale"])}
  },
  "competencies": {
    "epidemiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiologia"])},
    "tads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malattie animali transfrontaliere"])},
    "disease_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmi di prevenzione e controllo delle malattie"])},
    "biosecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biosicurezza"])},
    "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campionamento"])},
    "animal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificazione degli animali e controllo dei movimenti"])},
    "emergency_disaster_mgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione delle emergenze e dei disastri"])},
    "emergency_prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparazione alle emergenze"])},
    "emergency_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta alle emergenze"])},
    "vet_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotti veterinari"])},
    "animal_welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benessere degli animali"])},
    "risk_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazione dell'analisi del rischio"])},
    "safety_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicurezza, salute e benessere"])}
  },
  "compLevels": {
    "Awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consapevolezza"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principiante"])},
    "Competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competente"])},
    "Proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competente"])},
    "Expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperto"])}
  },
  "certificates": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "date_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di caricamento"])},
    "no_certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun certificato da visualizzare"])},
    "drag_n_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascinare e rilasciare i file pdf qui o fare clic per caricarli."])},
    "file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione massima del file: 2 MB"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificati"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricare un nuovo certificato"])}
  },
  "externalCourses": {
    "external_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi esterni"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizzazione"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona di contatto"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])}
  }
}