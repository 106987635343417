import {createStore} from "vuex"

export default createStore({
  state () {
    return {
      role: "",
      id: "",
      country: "",
      countryName: "",
      countryCities: []
    }
  },
  mutations: {
    changeRole (state, role) {
      state.role = role;
    },
    changeUser (state, id) {
      state.id = id;
    },
    changeCountry (state, country) {
      state.country = country;
    },
    changeCountryName (state, countryName) {
      state.countryName = countryName;
    },
    addCountryCities (state, countryCities) {
      state.countryCities = countryCities;
    }
  }
});