export default {
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User Data"])},
    "adjust_competency_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust Competency Levels"])},
    "custom_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Reports"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competency Framework"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Credit System"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominee"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a tailored course"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Features"])},
    "leave_a_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a Suggestion"])}
  },
  "cmHome": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Country Map"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "learners_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learners Registered"])},
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learners"])},
    "participants_courses_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants courses status"])},
    "tailored_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEPC National courses"])},
    "real_time_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RealTime Training Participants"])},
    "workshop_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop Participants"])},
    "online_course_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online course participants"])},
    "courses_by_status_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses By Status"])},
    "courses_by_role_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Courses By Role"])},
    "courses_by_year_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Courses By Year"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Credit System"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "average_number_of_courses_per_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Number of Courses per Participant"])},
    "compentencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencies"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "select_a_position_to_see_competencies_related_to this_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a position to see competencies related to this role"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Position"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Region"])},
    "select_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select competency"])},
    "assign_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign level"])},
    "learners_in_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learners in total: "])},
    "learners_with_this_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learners with this position: "])},
    "overview_of_required_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview of required competencies"])},
    "select_a_position_above_to_see_the_competency_levels_of_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a position above to see the competency levels of learners in that position."])},
    "competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competency details"])},
    "learn_more_about_the_competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the competency framework"])},
    "select_a_position_to_see_competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a position above to see the competency details for that position."])},
    "number_of_learners_per_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of learners per competency"])},
    "click_to_view_more_details_chart_on_the_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a competency to view more details. Chart on the right."])},
    "you_are_seeing_data_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are seeing data for"])},
    "Epidemiology_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiology is the study of factors determining and influencing the frequency and distribution of disease or other health related events and their causes in a defined population for the purpose of controlling their development and spread and establishing programmes to prevent development and spread."])},
    "TADS_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TADS are diseases which are highly contagious or transmissible and have the potential for very rapid spread, irrespective of national borders. Disease profiling refers to the Clinical signs, etiology, pathogenesis, clinical course, transmission potential, epidemiology of the disease. Control and prevention are found within the competency of disease control and prevention. TADs include African swine fever, highly pathogenic avian influenza, contagious bovine pleuropneumonia, haemorrhagic septicaemia, influenza A, rinderpest, rift valley fever , middle east respiratory syndrome coronavirus and foot and mouth disease."])}
  },
  "Epidemiology": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiology is the study of factors determining and influencing the frequency and distribution of disease or other health related events and their causes in a defined population for the purpose of controlling their development and spread and establishing programmes to prevent development and spread."])}
  },
  "Transboundary Animal Diseases": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TADS are diseases which are highly contagious or transmissible and have the potential for very rapid spread, irrespective of national borders. Disease profiling refers to the Clinical signs, etiology, pathogenesis, clinical course, transmission potential, epidemiology of the disease. Control and prevention are found within the competency of disease control and prevention. TADs include African swine fever, highly pathogenic avian influenza, contagious bovine pleuropneumonia, haemorrhagic septicaemia, influenza A, rinderpest, rift valley fever , middle east respiratory syndrome coronavirus and foot and mouth disease."])}
  },
  "userData": {
    "single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single User"])},
    "bulk_user_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk User Upload"])},
    "add_a_single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Single user"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select position"])},
    "csv_upload_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is a template for the format of the .CSV upload file"])},
    "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Template"])},
    "cvs_confirmation_positions_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure that these positions are entered in your .CSV file exactly as they appear here. These are the roles with attached competencies for your country."])},
    "upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your .CSV file here"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust Level"])},
    "click_to_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Save Results"])}
  },
  "adjustCompetency": {
    "position_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Competencies "])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competency"])},
    "level_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Level Required"])},
    "view_and_adjust_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can view the positions for your country, competencies andlevels required. You can also adjust the level required."])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by position or competency"])}
  },
  "learners": {
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learner"])},
    "change_the_position_or_region_in_the_header_above_to_filter_the_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the position or region in the header above to filter the table"])},
    "all_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Learners"])},
    "only_learners_with_competency_gaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only learners with competency gaps"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full name"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
    "competencies_at_required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" competencies at required level %"])},
    "enrol_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrol Learners"])},
    "mark_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark Complete"])},
    "enrolled_course_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enrolled course completion %"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Region"])},
    "list_of_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of positions"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "no_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No level"])},
    "awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awareness"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
    "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competent"])},
    "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficient"])},
    "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User Data"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by position or competency"])},
    "changing_competency_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing the competency level of"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Report"])},
    "select_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select course category"])},
    "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Report"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Courses"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" My Courses"])},
    "search_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for your course. If your course does not appear in the list, you will have the option to add a new course."])},
    "course_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course name"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "shortname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortname"])},
    "enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enrolled courses"])},
    "date_enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Enrolled"])},
    "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Completed"])},
    "add_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Learner"])},
    "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Date"])},
    "add_a_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add A New Course"])},
    "choose_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Learner"])},
    "choose_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Course"])},
    "if_course_not_above_add_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your course does not appear above or in the list of all courses, click the button below to add a new course."])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competency Framework"])},
    "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download this document "])},
    "click_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a competency to see more information about it, "])},
    "click_competency_framework_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or to check the Competency Framework details."])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current level"])},
    "required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required level"])},
    "enrolled_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrolled categories"])},
    "add_current_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Your Current Region"])},
    "enter_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter region here"])}
  },
  "courseList": {
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for your course. If your course does not appear in the list, you will have the option to add a new course."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a course..."])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not added any courses yet."])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])}
  },
  "singleLearner": {
    "competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competencies"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "self_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Assessment"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "year_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year Completed"])},
    "no_open_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no open courses."])},
    "no_closed_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no completed courses"])},
    "get_prepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Prepared"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behaviour"])},
    "pcp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCP"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])}
  },
  "selfAssessment": {
    "section_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 1"])},
    "section_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 2"])},
    "section_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 3"])},
    "section_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 4"])},
    "section_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 5"])},
    "section_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 6"])},
    "section_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 7"])},
    "section_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 8"])},
    "section_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 9"])},
    "section_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 10"])},
    "section_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 11"])},
    "section_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 12"])},
    "section_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 13"])},
    "epi_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the principles of epidemiology and their application to general disease control procedures (Standard Operational Procedures (SOPs))."])},
    "epi_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how to conduct an epidemiological inquiry in case of occurrence of a reportable disease."])},
    "epi_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the epidemiological approaches used during animal emergencies and their impacts (individual vs population)."])},
    "epi_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With appropriate guidance, I can assist in the collection of epidemiological data during an outbreak of a reportable disease."])},
    "epi_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can lead an epidemiological investigation on a suspect/confirmed premises including determining disease transmission pathways within the premises."])},
    "epi_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can assess the quality of disease investigation data."])},
    "epi_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can determine the source of a disease outbreak and ensure it is supported by an appropriate epidemiological analysis."])},
    "epi_1_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can conduct a risk analysis."])},
    "epi_1_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to develop strategic response plans and predict the likelihood of achieving goals."])},
    "epi_1_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to lead advanced planning to control an outbreak and the recovery phase."])},
    "tads_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have knowledge (species, clinical signs, etc.) on common transboundary animal diseases (FMD, LSD, ASF, RVF) and the tools used for their prevention and control."])},
    "tads_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know where to find up-to-date disease distribution information."])},
    "tads_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of notifiable transboundary animal diseases and describe the pathogen, lesions, transmission, sampling and diagnostic methods as well as any need to personal protective equipment."])},
    "tads_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know about national reporting systems and know which are notifiable transboundary animal diseases."])},
    "tads_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can perform clinical examinations of individual animals and herds suspected of having a transboundary animal disease, age lesions, determine which animals to sample and explain the rationale of diagnostic and therapeutic tools."])},
    "tads_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can confidently diagnose a suspicion of a transboundary animal disease and report as required."])},
    "tads_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can provide appropriate recommendations when clinical signs are confusing."])},
    "tads_2_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the burden of transboundary animal diseases at a national level, including the socio-economic impact."])},
    "disease_prev_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the principles of transboundary animal disease control and their control programs."])},
    "disease_prev_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of which animal diseases require compulsory notification and the importance of “early detection system”, including how to access relative information on diseases and how to respond to them."])},
    "disease_prev_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to participate in disease control plans (e.g. surveillance, vaccination campaigns, post vaccine monitoring, test-and-cull/treat programmes)."])},
    "disease_prev_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to lead disease control plans, including coordinating with partners for their efficient implementation."])},
    "disease_prev_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can oversee local level public-private partnerships (including leading a team, accreditation, audit)."])},
    "disease_prev_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to guide others in the implementation of programs in complex situations and adapt procedures based on response needs."])},
    "disease_prev_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to develop public-private partnerships at a local level."])},
    "disease_prev_3_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can write disease control programmes based on best practices and evaluate their cost-effectiveness."])},
    "disease_prev_3_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to develop public-private partnerships at a national level."])},
    "bio_security_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of on-farm biosecurity measures and I can identify environments in which biosecurity is needed."])},
    "bio_security_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the impacts of biosecurity breaches and the relative mitigation measures."])},
    "bio_security_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to participate effectively in a disease outbreak as a member of a bioecurity team, including entering and leaving premises in a biosecure manner."])},
    "bio_security_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to recognize breaches in biosecurity and rectify it by applying the appropriate mitigation measures in low-risk situations."])},
    "bio_security_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can apply pathogen-specific biosecurity protocols in high disease transfer risk situations."])},
    "bio_security_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can lead a biosecurity team during an outbreak."])},
    "bio_security_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to adapt procedures based on response needs."])},
    "bio_security_4_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can develop and audit biosecurity plans for industry."])},
    "bio_security_4_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to assess veterinary response decisions and predict the likelihood of achieving goals."])},
    "bio_security_4_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to develop effective biosecurity protocols."])},
    "sampling_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the principles of sampling, including identifying situations that require sampling and the implications of sampling."])},
    "sampling_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know where to find reliable information on sampling plans and techniques."])},
    "sampling_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of sampling regulations, programs and zoonotic potential of animal diseases."])},
    "sampling_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can collect appropriate samples and package them (while ensuirng their integrity)."])},
    "sampling_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can provide advise on the collection of samples for diagnostic testing, interpret results, discuss factors that affect test outcomes, describe mechanisms of diagnotics tests, define diagnostic tests, their performance and limitations."])},
    "sampling_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can train others in appropriate sampling techniques."])},
    "sampling_5_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can develop effective sampling plans given the characteristics of the disease and sampling situation."])},
    "animal_id_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the methods used to identify animals and the control of animals, animal products, equipment and people, including control zones."])},
    "animal_id_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of industry movement patterns of animals, products and by products."])},
    "animal_id_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can effectively control animal movement off a suspect/infected premises and be a member of a control team during an outbreak."])},
    "animal_id_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can apply identification methods to animals (e.g. ear tags and tatoos) and use an ID database to trace animal movement history."])},
    "animal_id_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can issue certificates for animal movement in peace time."])},
    "animal_id_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can lead a team to enforce control zones during an outbreak and control animal movement in and out of a control zone."])},
    "animal_id_6_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can determine the limits of a control zone, modify it as required and determine if a situation qualifies for a unique exemption."])},
    "animal_id_6_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can train others on movement control."])},
    "animal_id_6_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can design appropriate movement control policies and support their improvement by monitoring programme results."])},
    "animal_id_6_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can accurately predict the likelihood of achieving goals and foresee impacts of veterinary decisions."])},
    "emergency_disaster_mgt_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the legislation governing emergency response, the role of veterinarians in emergency events and the coordination between governmental and non-governmental responders, authorities, and public and private entities."])},
    "emergency_disaster_mgt_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under approprate guidance I can participate in an emergency response."])},
    "emergency_disaster_mgt_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can apply the concept of incident coordination mechanisms, their functional capacity and the role of veterinarians, during an incident."])},
    "emergency_disaster_mgt_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can direct others during emergency responses."])},
    "emergency_disaster_mgt_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can effectively communicate crises at a local level."])},
    "emergency_disaster_mgt_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can coordinate complex incidents and identify the different sectors that are involved."])},
    "emergency_disaster_mgt_7_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can direct a complex incident at a local level."])},
    "emergency_disaster_mgt_7_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can communicate crises at a regional level."])},
    "emergency_disaster_mgt_7_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can direct national emergency responses and crisis communication at national level."])},
    "emergency_disaster_mgt_7_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can recognize political sensitivities and find solutions."])},
    "emergency_prep_8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the role of emergency preparedness planning for priority diseases and any relative preparedness plans."])},
    "emergency_prep_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know the value of approaching preparedness from an all hazards-perspective."])},
    "emergency_prep_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can use preparedness plans and I am aware of the relative regulations and Standard Operational Procedures (SOPs)."])},
    "emergency_prep_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can participate confidently in preparedness at local level."])},
    "emergency_prep_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to develop a site specific emergency plan (including sample collection) and exercise it."])},
    "emergency_prep_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can recognize suspected disease incursions and factors that impact the ability to respond."])},
    "emergency_prep_8_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can support with the design and running of simulation exercises and training."])},
    "emergency_prep_8_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can write operational manuals and support industry in the development of their business continuity and response plans."])},
    "emergency_prep_8_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can write and update national plans for disease control."])},
    "emergency_prep_8_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can develop auditing tools/SOPs for verification of activated plans."])},
    "emergency_res_9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of the activities that should be included in an incident response."])},
    "emergency_res_9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know where to locate guidance documents/Standard Operating Procedures (SOPs)."])},
    "emergency_res_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can prepare for and assist in an outbreak investigation."])},
    "emergency_res_9_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under supervision, I can use required equipment and perform technical skills during an outbreak (disposal methods, perform valuation, cleaning and disinfection, wildlife controls)."])},
    "emergency_res_9_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can lead reponders during an outbreak."])},
    "emergency_res_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can perform law enforcement related activities (preserve scene, custody of evidence etc)."])},
    "emergency_res_9_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can train others in emergency response."])},
    "emergency_res_9_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can evaluate outcomes, assess consequences of precedural changes and recommend response strategies."])},
    "emergency_res_9_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can approve recommendations and design response strategies."])},
    "emergency_res_9_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can define appropriate outcomes and predict the likelihood of achieving response goals."])},
    "vet_prod_10_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can advise and administer veterinary products to treat common animal diseases."])},
    "vet_prod_10_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can apply regulatory and administrative controls over antimicrobial resistance (AMR), antimicrobial use (AMU) and participate in their surveillance."])},
    "vet_prod_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can prescribe veterinary products."])},
    "vet_prod_10_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can find and interpret information regarding the link between use of antimicrobials in food animals and development of AMR in pathogens of human importance."])},
    "vet_prod_10_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of regulatory and administrative controls for import, manufacture and registration of veterinary biologics."])},
    "vet_prod_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can implement AMR surveillance."])},
    "vet_prod_10_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have the necessary skills to approve new veterinary products."])},
    "vet_prod_10_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can support the design of AMR surveillance programs."])},
    "vet_prod_10_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can design AMR surveillance programs and policies for regulating veterinary medicines and biologics."])},
    "vet_prod_10_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can recognise the implication of emergencies on supply chain interruptions and international restrictions on movement of biological products."])},
    "animal_welfare_11_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can identify animal welfare regulations/standards and describe responsabilities (owners, veterinarians, handlers)."])},
    "animal_welfare_11_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can use animal handling techniques and equipment in a manner that protects animal welfare recognizing signs of pain and distress."])},
    "animal_welfare_11_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to identify and report animal welfare problems, and participate in corrective actions."])},
    "animal_welfare_11_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to perform humane euthanasia on individual animals, including evaluation of stunning/bleeding and the application of restraint methods."])},
    "animal_welfare_11_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can lead a team in the mass depopulation of animals following protocols based on humane destruction methods."])},
    "animal_welfare_11_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to summarize welfare implications of various control methods (eg animal movement control)."])},
    "animal_welfare_11_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to guide others in the implementation of animal welfare programs in complex situations and adapt procedures based on response needs."])},
    "animal_welfare_11_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to select suitable humane killing methods for mass depopulation based on the specific situation."])},
    "animal_welfare_11_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can write compliance programmes using scientific literature, policy and guidelines."])},
    "animal_welfare_11_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can accurately predict the social acceptance for response actions and foresee impacts of veterinary decisions."])},
    "risk_12_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know about risk analysis concepts: hazard identification, risk assessment, rapid risk assessment, risk management, risk communication."])},
    "risk_12_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how risk analysis can be used to ensure animal, human and environmental health are protected in disaster situations."])},
    "risk_12_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can find information needed to carry out a risk analysis."])},
    "risk_12_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know how risk analysis can be applied to assessment of risk of animal disease emergency planning/mitigation/preparation response and recovery, residues of veterinary drugs, including importation of animals and animal products."])},
    "risk_12_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can use basic/key concepts and principles of risk analysis in accordance with prescribed policy and procedures/guidelines."])},
    "risk_12_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can map local value chains and convey disease transmission risks."])},
    "risk_12_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can apply risk analysis principles in unusual/complex situations and support decision making during complex situations."])},
    "risk_12_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can identify and manage communication plans related to disease transmission risks."])},
    "risk_12_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can carry out organization-level risk analysis."])},
    "risk_12_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can support risk management policy decisions and risk communication strategies."])},
    "safety_13_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of workspace and health hazards and the principles of animal, human and environmental safety in emergency situations."])},
    "safety_13_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am aware of emergency and disaster management implications on livelihoods."])},
    "safety_13_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can identify sources of support (personal and organizational)."])},
    "safety_13_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can recognize work environments that do NOT comply with the appropriate health and safety standards."])},
    "safety_13_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can recognize signs of deteriorating well being in others (mental first aid) and intervene."])},
    "safety_13_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am able to take corrective actions to rectify working conditions leading to an unsafe work environment."])},
    "safety_13_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can lead investigations into accidents or near misses."])},
    "safety_13_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can make recommendations to reduce the likelihood of mental or physical health hazards."])},
    "safety_13_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can write/update policy and procedures to protect employee safety, health and wellbeing."])},
    "rank_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please rank the level at which you agree with the following statements."])},
    "survey_sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey Sections"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the TOM Self Assessment"])},
    "welcome_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The TOM Self Assessment is a tool to help you understand your current level of competency in the areas tracked in the Disease Control Competency Framework. Each section is designed to be completed in 10-15 minutes."])},
    "welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be asked to rate your level of confidence in your knowledge or ability to perform tasks that represent varying levels of expertise in the framework's 13 competencies."])},
    "click_survey_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on any of the survey sections in the side panel to get started."])},
    "thank_you_for_completing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for completing the TOM Self-Assessment!"])},
    "assessment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "section_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section Scores"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strongly agree"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not agree"])},
    "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Applicable"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Assessment Questionnaire - Disease Control Competency Framework"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])}
  },
  "routes": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "PasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PasswordReset"])},
    "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "Nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominee"])},
    "Request Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Course"])},
    "Training Credit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Credit System"])},
    "Suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestions"])},
    "Future Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Features"])},
    "Single Learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Learner"])},
    "Competency Framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competency Framework"])},
    "Custom Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Report"])},
    "Add User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User Data"])},
    "Adjust Levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdjustLevels"])},
    "admin_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Login"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "Learner Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learner Profile"])}
  },
  "positions": {
    "central_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central veterinarian"])},
    "regional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional veterinarian"])}
  },
  "regions": {
    "armenia": {
      
    }
  },
  "competencies": {
    "epidemiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epidemiology"])},
    "tads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transboundary Animal Diseases"])},
    "disease_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disease Prevention and Control Programs"])},
    "biosecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biosecurity"])},
    "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampling"])},
    "animal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal Identification and Movement Control"])},
    "emergency_disaster_mgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency and Disaster Management"])},
    "emergency_prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Preparedness"])},
    "emergency_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Response"])},
    "vet_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veterinary Products"])},
    "animal_welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal Welfare"])},
    "risk_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application of Risk Analysis"])},
    "safety_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety, Health and Wellbeing"])}
  },
  "compLevels": {
    "Awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awareness"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
    "Competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competent"])},
    "Proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficient"])},
    "Expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])}
  },
  "certificates": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "date_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Uploaded"])},
    "no_certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No certificates to display"])},
    "drag_n_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop pdf files here or click to upload"])},
    "file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max file size: 2 MB"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new certificate"])}
  },
  "externalCourses": {
    "external_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Courses"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  }
}