const supportedLocales = {
  en: "English",
  it: "Italian",
  //tr: "Türkçe",
  rus: "Russian",
  es: "Spanish",
  srb: "Serbian",
};
// console.log(Object.keys(supportedLocales));
export const getSupportedLocales = () => {
  let annotatedLocales = [];

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code],
    });
  }
  // creates: { code: "en", name: "English"}
  return annotatedLocales;
};

// check whether a local from the browser is supported

export const supportedLocalesInclude = locale => {
  return Object.keys(supportedLocales).includes(locale);
};
