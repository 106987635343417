<template>
	<div
		class="modal fixed w-full h-full top-0 left-0 flex items-center z-50 justify-center"
	>
		<div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

		<div
			class="bg-white min-w-min  mx-auto rounded shadow-lg z-50 overflow-y-auto"
		>
			<div class="py-4 text-left px-6 space-y-2">
				<div class="flex justify-end items-center">
					<font-awesome-icon
						class="cursor-pointer z-50 hover:text-green-forest"
						icon="times"
						@click="closeModal"
					/>
				</div>
				<h1 class="font-bold text-center ">{{ $t("learners.add_current_region") }}</h1>
				<form class="mx-auto min-w-min px-4">
                    <input
					v-model="searchQuery"
					type="search"
					id="default-search"
					class="w-full border focus:ring-green-tom focus:border-green-tom focus:outline-none focus:ring-1 rounded px-4 py-2 mb-2"
					:placeholder="$t('learners.enter_region')"
					required
				/>
				</form>

					<div v-if="queryResults.length">
						<p>Showing {{queryResults.length}} of {{regions.length}}</p>
						<br>
						<ul class="w-full overflow-auto" style="height:200px">
							<li 
							v-for="region in queryResults" 
							:key="region"
							@click="addRegion(region)"
							>
								{{region}}
							</li>
						</ul>

					</div>

					<div>
						{{noRegion}}
					</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/services/data.js";
export default {
	name: "AddRegionModal",
	data() {
		return {
			searchQuery: null,
			queryResults: [],
			noRegion:" ",
			loading: false
		};
	},
	props: {
		regions: {
			//type: Array,
			required: true,
		},
		country: {
			type: String,
		}
	},
	computed: {
		queryNoResults() {
			return !this.queryResults.length && this.searchQuery;
		}
	},
	methods: {
		closeModal() {
			this.$emit("closeModal");
		},
		async addRegion(region) {
			if (!region) {
				this.$toast("Please select a region.", {
					styles: {
						backgroundColor: "#f5f5f5",
					},
				});
			} else {
				this.$toast(`You have selected ${region}`, {
					styles: {
						backgroundColor: "#f5f5f5",
					},
				});
				
				const response = await api.addRegion(region);
				
				if (response.status === 200) {
					
					this.selectedRegion = null;
					this.$toast("Region added successfully.", {
						styles: {
							backgroundColor: "#f5f5f5",
						},
					});

					this.$emit("closeModal");
					this.$emit("regionAdded");
				
				} else {
					this.$toast(
						"An error occurred while trying to add your region. Please try again.",
						{
							styles: {
								backgroundColor: "#ffe7e7",
							},
						}
						);
					}
				}
			}
		},
	watch: {
		searchQuery(value) {
			if(!this.regions) {
				this.$toast(`There are no regions available.`, {
					styles: {
						backgroundColor: "#CADFBF",
					},
				});
			}
			let results = [];
			
			let matches = 0
			if (value) {
				results = this.regions.filter((city) => {
					return this.searchQuery.toLowerCase().split(" ").every((word) => city.toLowerCase().includes(word)) && matches < 2;
					
				});
				if(results.length === 0){
					this.noRegion = " Your region was not found. Please select the nearest region"
					this.queryResults = [];
				}
				else {
					matches++;
					this.queryResults = results
				}
			}
		}
	}
};
</script>

<style></style>
