export default {
  "navigation": {
    "country_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер проекта по стране"])},
    "side_navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боковое меню навигации"])}
  },
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная страница"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить данные пользователя"])},
    "adjust_competency_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить уровни компетенции"])},
    "custom_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты пользователя"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система компетенций"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная система обучения"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кандидат"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос индивидуального курса"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущие возможности"])},
    "leave_a_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить предложение"])}
  },
  "cmHome": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная страница"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
    "view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр карты"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["карты"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вскоре"])},
    "learners_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировавшиеся участники учебного курса"])},
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники учебной программы"])},
    "participants_courses_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус участников курса"])},
    "tailored_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные курсы"])},
    "real_time_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники в режиме реального времени"])},
    "workshop_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники семинара"])},
    "online_course_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники онлайн-курса"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная система обучения"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончившие"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжают"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос индивидуального курса"])},
    "average_number_of_courses_per_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее количество курсов на одного участника"])},
    "compentencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетенции"])},
    "select_a_position_to_see_competencies_related_to this_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должность, чтобы увидеть относящиеся к ней компетенции"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать должность"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать Уровни"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать регион"])},
    "select_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать Компетенции"])},
    "assign_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение уровня"])},
    "learners_in_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники учебной программы, всего: "])},
    "learners_with_this_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["участники в этой позиции: "])},
    "overview_of_required_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор необходимых компетенций"])},
    "select_a_position_above_to_see_the_competency_levels_of_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["компетенции участников, занимающих такую должность."])},
    "competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали компетенции"])},
    "learn_more_about_the_competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше узнать о системе компетенций"])},
    "select_a_position_to_see_competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["увидеть подробное описание деталей компетенций, необходимых сотруднику на этой должности"])},
    "number_of_learners_per_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество участников учебной программы по каждой компетенции"])},
    "click_to_view_more_details_chart_on_the_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кликните на компетенцию, чтобы просмотреть более подробную информацию. График справа."])},
    "you_are_seeing_data_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы смотрите данные для"])},
    "Epidemiology_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эпидемиология – наука, изучающая факторы, определяющие возникновение, частоту и распространение заболеваний или иных связанных со здоровьем событий и их причин в определенной популяции в целях профилактики и контроля их развития и распространения и разработки программ по предотвращению развития и распространения."])},
    "TADS_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТБЖ – высококонтагиозные заболевания, способные стремительно распространяться, независимо от национальных границ. Составление профиля болезни включает описание клинических симптомов, этиологии, патогенеза, клинического течения, потенциала передачи и эпидемиологии болезни. Контроль и профилактика входят в компетенцию контроля и профилактики заболеваний. К ТБЖ относятся африканская чума свиней, высокопатогенный птичий грипп, контагиозная плевропневмония крупного рогатого скота, геморрагическая геморрагическую септицемию, грипп типа A, чуму крупного рогатого скота, лихорадку долины Рифт, вызываемый коронавирусом ближневосточный респираторный синдром и ящур."])}
  },
  "Epidemiology": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эпидемиология – наука, изучающая факторы, определяющие возникновение, частоту и распространение заболеваний или иных связанных со здоровьем событий и их причин в определенной популяции в целях профилактики и контроля их развития и распространения и разработки программ по предотвращению развития и распространения."])}
  },
  "Transboundary Animal Diseases": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТБЖ – высококонтагиозные заболевания, способные стремительно распространяться, независимо от национальных границ. Составление профиля болезни включает описание клинических симптомов, этиологии, патогенеза, клинического течения, потенциала передачи и эпидемиологии болезни. Контроль и профилактика входят в компетенцию контроля и профилактики заболеваний. К ТБЖ относятся африканская чума свиней, высокопатогенный птичий грипп, контагиозная плевропневмония крупного рогатого скота, геморрагическая геморрагическую септицемию, грипп типа A, чуму крупного рогатого скота, лихорадку долины Рифт, вызываемый коронавирусом ближневосточный респираторный синдром и ящур."])}
  },
  "userData": {
    "single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единичный пользователь"])},
    "bulk_user_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовая загрузка пользователей"])},
    "add_a_single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить одного пользователя"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл."])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регион"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["необязательно"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать должность"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить данные пользователя"])},
    "csv_upload_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон в формате Загрузки CSV"])},
    "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать шаблон"])},
    "cvs_confirmation_positions_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что эти должности введены в ваш файл .CSV в том же порядке, что на сайте. Это функциональные обязанности с соответствующими компетенциями для вашей страны."])},
    "upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачайте ваш файл .CSV здесь"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представить на рассмотрение"])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить уровни"])}
  },
  "adjustCompetency": {
    "position_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должностные компетенции"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должностные"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["компетенции"])},
    "level_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["требуемый уровень"])},
    "view_and_adjust_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете увидеть должности, компетенции и требуемые уровни для вашей страны. Вы также можете настроить требуемый уровень."])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по должности или компетенции"])}
  },
  "learners": {
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники учебной программы"])},
    "change_the_position_or_region_in_the_header_above_to_filter_the_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить позицию или регион в заголовке выше, чтобы отфильтровать таблицу"])},
    "all_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все участники учебной программы"])},
    "only_learners_with_competency_gaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только участники с пробелами в компетенциях"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное имя"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
    "competencies_at_required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетенции на требующемся уровне"])},
    "enrol_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировавшийся участник"])},
    "mark_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметка об окончании"])},
    "date_enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата зачисления"])},
    "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
    "add_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить участника учебной программы"])},
    "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить дату"])},
    "enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачисленые на курсыi"])},
    "enrolled_course_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завершившие обучение"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие региона"])},
    "list_of_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список должностей"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровни"])},
    "no_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие уровня"])},
    "awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая осведомленность"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новичок"])},
    "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетентный"])},
    "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессионал"])},
    "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эксперт"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить данные пользователя"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по позиции или компетенции"])},
    "changing_competency_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить уровень компетенции"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользовательский отчет"])},
    "select_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать категорию курса"])},
    "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать отчет"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курса"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все курсы"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои курсы"])},
    "search_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите ваш курс. Ели ваш курс не указан в списке, вы сможете добавить новый курс."])},
    "course_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название курса"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "shortname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенное наименование"])},
    "choose_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать Участники учебной программы"])},
    "choose_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать курса"])},
    "choose_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать категорию курса"])},
    "add_a_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новый курс."])},
    "if_course_not_above_add_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ели ваш курс не указан в списке, вы сможете добавить новый курс."])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система компетенций"])},
    "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать этот документ"])},
    "click_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кликните по названию компетенции, чтобы увидеть более подробную информацию, или кликните здесь,"])},
    "click_competency_framework_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы увидеть более подробную информацию о Системе компетенций."])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий уровень"])},
    "required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуемый уровень"])},
    "enrolled_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачисленные категории"])},
    "add_current_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте свой регион"])},
    "enter_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Писать свой регион"])}
  },
  "courseList": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите курс..."])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предыдущий"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["следующий"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последний"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие Курсы"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница"])}
  },
  "singleLearner": {
    "competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетенции"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "self_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Селф Ассессмент"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ход курса"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус завершения курса"])},
    "year_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год окончания курса"])},
    "no_open_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет незаконченных курсов"])},
    "no_closed_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет законченных курсов"])},
    "get_prepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подготовительные курсы"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поведенческие индикаторы"])},
    "pcp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПЛАН ПОЭТАПНОЙ БОРЬБЫ С ЯЩУРОМ"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл. пота"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пароля"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["войти в систему"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])}
  },
  "selfAssessment": {
    "section_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 1"])},
    "section_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 2"])},
    "section_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 3"])},
    "section_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 4"])},
    "section_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 5"])},
    "section_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 6"])},
    "section_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 7"])},
    "section_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 8"])},
    "section_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 9"])},
    "section_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 10"])},
    "section_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 11"])},
    "section_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 12"])},
    "section_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 13"])},
    "epi_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю эпидемиологические принципы и их применение к общим процедурам борьбы с заболеваниями (стандартные операционные процедуры (СОП))."])},
    "epi_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, как проводить эпидемиологическое расследование в случае возникновения болезни, подлежащей уведомлению ветеринарных органов."])},
    "epi_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю об эпидемиологических подходах, применяемых в случаях чрезвычайных ситуаций, вызванных заболеваниями животных, и их воздействии (на индивидуальном уровне и на уровне популяции)."])},
    "epi_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При соответствующем руководстве я могу ассистировать в сборе эпидемиологических данных во время вспышки болезни, подлежащей уведомлению ветеринарных органов."])},
    "epi_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить эпидемиологическим расследованием в предполагаемом/подтвержденном зараженном помещении, включая определение пути передачи возбудителя болезни."])},
    "epi_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу оценить качество данных эпидемиологического расследования."])},
    "epi_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу определить источник вспышки и обеспечить его подтверждение соответствующим эпидемиологическим анализом."])},
    "epi_1_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу проводить анализы риска."])},
    "epi_1_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать стратегические планы реагирования на чрезвычайные ситуации и прогнозировать вероятность достижения поставленных целей."])},
    "epi_1_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить перспективным планированием борьбы с болезнью и последующего восстановления."])},
    "tads_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я обладаю знаниями (поражаемые виды, клинические признаки и т. д.) о распространенных трансграничных болезнях животных (ящур, нодулярный дерматит КРС, африканская чума свиней, лихорадка долины Рифт) и инструментах, используемых для профилактики этих болезней и борьбы с ними."])},
    "tads_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, где искать актуальную информацию о структуре заболеваемости ТБЖ."])},
    "tads_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я обладаю знаниями о ТБЖ, подлежащих нотификации, и могу описать патоген, патологические изменения, пути передачи, отбор образцов и диагностические методы, а также необходимость в средствах индивидуальной защиты по каждой из данных ТБЖ."])},
    "tads_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я обладаю знаниями о национальных системах отчетности и том, какие ТБЖ подлежат нотификации. "])},
    "tads_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу проводить клинический осмотр отдельных животных и стада с подозрением на ТБЖ, определять время образования патологических изменений, выбирать животных для отбора образцов и обосновывать применение диагностических инструментов и терапевтических средств."])},
    "tads_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу с уверенностью поставить диагноз при подозрении на трансграничную болезнь животных и отправить отчет в соответствии с существующими требованиями."])},
    "tads_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу давать обоснованные рекомендации в тех случаях, когда клинические признаки неясны."])},
    "tads_2_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о бремени ТБЖ на национальном уровне, включая социально-экономические последствия."])},
    "disease_prev_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю принципы борьбы с трансграничными болезнями животных и соответствующие программы борьбы."])},
    "disease_prev_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, какие болезни животных требуют обязательной нотификации, и знаю о важности 'системы раннего выявления', в том числе о том, как получить актуальную информацию о болезнях и какие меры принимать."])},
    "disease_prev_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу участвовать в выполнении планов борьбы с болезнями (эпидемиологический надзор, кампании по вакцинации, поствакцинальный мониторинг, программы тестирования и выбраковки/лечения)."])},
    "disease_prev_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить выполнением плана по борьбе с болезнями, включая координацию действий с партнерами для эффективной реализации плана"])},
    "disease_prev_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу осуществлять надзор за государственно-частными партнерствами на местном уровне (включая руководство группой, аккредитацию, аудит)."])},
    "disease_prev_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить реализацией программ в сложных ситуациях и адаптировать процедуры на основе потребностей в мерах реагирования. "])},
    "disease_prev_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу развивать государственно-частные партнерства на местном уровне."])},
    "disease_prev_3_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать программы борьбы с болезнями, основанные на передовых методах, и оценивать их экономическую эффективность. "])},
    "disease_prev_3_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу развивать государственно-частные партнерства на национальном уровне."])},
    "bio_security_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о мерах биобезопасности на уровне хозяйства и могу определить условия, в которых требуется соблюдение биобезопасности."])},
    "bio_security_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о последствиях нарушения биобезопасности и соответствующих мерах по смягчению последствий."])},
    "bio_security_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу принимать активное участие в ликвидации вспышки заболевания в составе группы по обеспечению биобезопасности, в том числе входить и выходить в помещения с соблюдением правил биобезопасности. "])},
    "bio_security_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу идентифицировать нарушения требований биобезопасности и принимать меры по их исправлению и смягчению риска в ситуациях с низким уровнем риска передачи."])},
    "bio_security_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу применять патоген-специфические протоколы биобезопасности в ситуациях с высоким уровнем риска передачи."])},
    "bio_security_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить группой по обеспечению биобезопасности во время вспышки."])},
    "bio_security_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу адаптировать процедуры обеспечения биобезопасности на основе потребностей в мерах реагирования."])},
    "bio_security_4_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать и проверять планы биобезопасности для отрасли."])},
    "bio_security_4_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу оценить решения в области ветеринарных мер реагирования и прогнозировать вероятность достижения желаемых целей"])},
    "bio_security_4_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать эффективные протоколы биобезопасности."])},
    "sampling_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю принципы отбора образцов, включая определение ситуаций, требующих отбора образцов, и понимаю значение этой процедуры."])},
    "sampling_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, где находить достоверную информацию по планам и методам отбора образцов."])},
    "sampling_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю правила отбора образцов, программы отбора и зоонозный потенциал болезней животных."])},
    "sampling_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу отбирать необходимые образцы и упаковывать их для транспортировки, обеспечивая их сохранность и целостность."])},
    "sampling_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу давать рекомендации по отбору образцов для диагностических целей, интерпретировать результаты анализов, анализировать факторы, влияющие на результаты тестов, описывать механизмы диагностических тестов, определять диагностические тесты, их характеристики и ограничения."])},
    "sampling_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу обучать надлежащим методам отбора образцов."])},
    "sampling_5_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать планы отбора образцов с учетом характеристик болезни и ситуации."])},
    "animal_id_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о методах идентификации животных и методах, используемых для контроля перемещений животных, продукции животноводства, оборудования и людей, в том числе в контролируемых зонах."])},
    "animal_id_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю отраслевые модели перемещения животных, продукции животноводства и отходов."])},
    "animal_id_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу эффективно контролировать перемещение животных из подозреваемых в заражении / зараженных помещений и участвовать в работе группы по контролю за перемещением животных во время вспышки заболевания."])},
    "animal_id_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу применять методы идентификации животных (например, ушные бирки и татуировки) и использовать базу данных для отслеживания истории перемещения животных."])},
    "animal_id_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу выдавать ветеринарные сертификаты на перемещение животных в обычных условиях."])},
    "animal_id_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить группой, обеспечивающей установление контролируемых зон во время вспышки и контролировать перемещение животных в контролируемую зону и из нее."])},
    "animal_id_6_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу определять границы контролируемой зоны, изменять их по мере необходимости и решать, можно ли квалифицировать отдельные случаи как особое исключение из правил."])},
    "animal_id_6_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу проводить обучение контролированию перемещений."])},
    "animal_id_6_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать меры политики в области контроля перемещений способствовать их улучшению на основе мониторинга результатов."])},
    "animal_id_6_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу с точностью прогнозировать вероятность достижения поставленных целей и предусматривать последствия ветеринарных решений."])},
    "emergency_disaster_mgt_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю законодательство, регулирующее меры реагирования на чрезвычайные ситуации, роль ветеринарных служб в чрезвычайных ситуациях и координацию действий между правительственными и неправительственными службами реагирования, органами власти и государственными и частными организациями."])},
    "emergency_disaster_mgt_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Под соответствующим руководством я могу участвовать в мерах реагирования на чрезвычайные ситуации."])},
    "emergency_disaster_mgt_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во время инцидента я могу использовать механизмы координации действий в условиях чрезвычайных ситуаций, их функциональные возможности и роль ветеринаров."])},
    "emergency_disaster_mgt_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В период чрезвычайного реагирования я могу выполнять руководящие функции."])},
    "emergency_disaster_mgt_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу эффективно оповещать о кризисных ситуациях на местном уровне."])},
    "emergency_disaster_mgt_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу координировать мероприятия во время сложных инцидентов и определять затронутые секторы."])},
    "emergency_disaster_mgt_7_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить мероприятиями во время сложного инцидента на местном уровне."])},
    "emergency_disaster_mgt_7_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу оповещать о кризисных ситуациях на уровне района "])},
    "emergency_disaster_mgt_7_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить национальными мерами реагирования на чрезвычайные ситуации и кризисной коммуникацией на национальном уровне."])},
    "emergency_disaster_mgt_7_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу оценивать сложность политической ситуации и находить решения."])},
    "emergency_prep_8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я понимаю важность планирования мероприятий по обеспечению готовности к чрезвычайным ситуациям, связанным с приоритетными болезнями, и соответствующих планов."])},
    "emergency_prep_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я понимаю важность всестороннего подхода к готовности ко всем типам угроз."])},
    "emergency_prep_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу использовать планы обеспечения готовности и знаю соответствующие регламенты и стандартные операционные процедуры (СОП)."])},
    "emergency_prep_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу с уверенностью участвовать в мероприятиях по обеспечению готовности на местном уровне."])},
    "emergency_prep_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разработать план обеспечения готовности к чрезвычайным ситуациям для конкретного объекта (включая отбор образцов) и реализовать его."])},
    "emergency_prep_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу определить подозрительные случаи заболевания и факторы, влияющие на способность реагирования на чрезвычайные ситуации."])},
    "emergency_prep_8_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу поддерживать разработку и проведение имитационных учений и тренингов."])},
    "emergency_prep_8_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу составлять оперативные руководства и содействовать предприятиям отрасли в выработке планов обеспечения непрерывности бизнес-процессов и реагирования на чрезвычайные ситуации."])},
    "emergency_prep_8_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу составлять и обновлять национальные планы по борьбе с болезнями."])},
    "emergency_prep_8_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать инструменты аудита/СОП для проверки введенных в действие планов."])},
    "emergency_res_9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о мероприятиях, которые должны быть включены в план реагирования на инциденты."])},
    "emergency_res_9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, где находить руководящие документы и стандартные операционные процедуры (СОП)."])},
    "emergency_res_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу подготовиться к проведению расследования вспышки и принять в нем участие."])},
    "emergency_res_9_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Под надзором опытных сотрудников я могу во время вспышки использовать требуемое оборудование и выполнять техническую работу (утилизация, оценка, очистка и дезинфекция, контроль за дикими животными)."])},
    "emergency_res_9_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во время вспышки я могу руководить сотрудниками службы реагирования."])},
    "emergency_res_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу участвовать в правоохранительной деятельности (сохранение места происшествия, обеспечение сохранности образцов и т. д.)."])},
    "emergency_res_9_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу проводить тренинги по мерам быстрого реагирования."])},
    "emergency_res_9_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу оценивать результаты изменений в процедурах и рекомендовать стратегии реагирования."])},
    "emergency_res_9_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу утверждать рекомендации и разрабатывать стратегии реагирования."])},
    "emergency_res_9_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу определить соответствующие результаты и прогнозировать вероятность достижения целей реагирования на чрезвычайные ситуации."])},
    "vet_prod_10_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу назначать и давать ветеринарные препараты для лечения распространенных болезней животных."])},
    "vet_prod_10_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу применять меры регулирующего и административного контроля за применением противомикробных препаратов (ППП) и устойчивостью к противомикробным препаратам (УПП) и участвовать в надзоре за УПП и ППП."])},
    "vet_prod_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу выписывать ветеринарные препараты."])},
    "vet_prod_10_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу находить и интерпретировать информацию о связи между применением противомикробных препаратов у продуктивных животных и развитием устойчивости к противомикробным препаратам у патогенов, опасных для человека."])},
    "vet_prod_10_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о нормативных и административных мерах контроля за импортом, производством и регистрацией ветеринарных и биологических препаратов."])},
    "vet_prod_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу осуществлять план надзора за УПП."])},
    "vet_prod_10_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я обладаю необходимой квалификацией для утверждения новых ветеринарных продуктов."])},
    "vet_prod_10_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу содействовать выработке программ надзора за УПП."])},
    "vet_prod_10_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать программы и политику надзора за УПП для регулирования ветеринарных и биологических препаратов."])},
    "vet_prod_10_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я осознаю влияние чрезвычайных ситуаций на перебои в цепочках поставок и международные ограничения на перемещение биологических продуктов."])},
    "animal_welfare_11_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу привести нормы/стандарты благополучия животных и описать соответствующие обязанности (владельцев, ветеринаров, работников хозяйств)."])},
    "animal_welfare_11_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу использовать методы обращения с животными и оборудование таким образом, чтобы обеспечить благополучие животных, распознавая признаки боли и стресса."])},
    "animal_welfare_11_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу выявлять проблемы с благополучием животных, сообщать о таких проблемах и принимать участие в устранении выявленных нарушений."])},
    "animal_welfare_11_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу провести гуманную эвтаназию отдельных животных, включая оценку оглушения/обескровливания и применение методов фиксации."])},
    "animal_welfare_11_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить группой при массовом забое животных с использованием гуманных методов."])},
    "animal_welfare_11_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу формулировать последствия различных методов контроля (таких как контроль за перемещением) для здоровья и благополучия животных."])},
    "animal_welfare_11_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить осуществлением программ благополучия животных в сложных ситуациях и адаптировать процедуры в соответствии с потребностями мер реагирования на чрезвычайные ситуации."])},
    "animal_welfare_11_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу выбирать гуманные методы при массовом забое."])},
    "animal_welfare_11_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я обладаю навыками написания программ, обеспечивающих соблюдение норм и правил, с использованием научной литературы, стратегий и руководств."])},
    "animal_welfare_11_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу с точностью прогнозировать социальную приемлемость мер реагирования и предусматривать последствия ветеринарных решений."])},
    "risk_12_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю понятия, относящиеся к анализу риска: выявление факторов риска, оценка риска, оперативная оценка риска, управление риском, информирование о риске."])},
    "risk_12_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, как анализ риска может использоваться для обеспечения защиты здоровья животных, людей и окружающей среды в условиях стихийных бедствий."])},
    "risk_12_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу находить информацию, необходимую для проведения анализа риска."])},
    "risk_12_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю, как анализ риска может применяться для оценки риска возникновения чрезвычайной ситуации, связанной с болезнью животных, смягчения риска, планирования мер реагирования и восстановления, оценки риска, связанного с остатками ветеринарных препаратов, в том числе для импорта животных и продукции животноводства."])},
    "risk_12_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу использовать основные/ключевые понятия и принципы анализа риска в соответствии с предписанной политикой и процедурами/руководствами."])},
    "risk_12_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу составить схему местных производственно-сбытовых цепочек и информировать о рисках передачи возбудителя болезни."])},
    "risk_12_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу применять принципы анализа риска в нетипичных/сложных ситуациях и поддерживать принятие решений в сложных ситуациях."])},
    "risk_12_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу выявлять коммуникационные планы, связанные с риском передачи возбудителя заболевания и управлять такими планами."])},
    "risk_12_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу проводить анализ риска на уровне организации."])},
    "risk_12_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу оказывать поддержку принятию решений в области политики управления риском и стратегиям информирования о риске."])},
    "safety_13_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о факторах риска на рабочем месте и угрозах здоровью, а также о принципах обеспечения безопасности животных, людей и окружающей среды в условиях чрезвычайных ситуаций."])},
    "safety_13_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я знаю о влиянии управления при чрезвычайных ситуациях и стихийных бедствиях на источники средств к существованию."])},
    "safety_13_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу определить источники поддержки (личные и организационные)."])},
    "safety_13_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу распознать рабочую среду, которая НЕ соответствует надлежащим стандартам гигиены труда и техники безопасности."])},
    "safety_13_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу заметить признаки ухудшения психофизического состояния у коллег (необходимость первой психологической помощи) и принять меры."])},
    "safety_13_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу принять меры для исправления условий труда, создающих небезопасную рабочую среду."])},
    "safety_13_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу руководить расследованием несчастных случаев или потенциально опасных происшествий."])},
    "safety_13_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу давать рекомендации по снижению вероятности возникновения угроз психическому или физическому здоровью."])},
    "safety_13_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я могу разрабатывать/обновлять документы по политике и процедурам обеспечения безопасности, здоровья и благополучия сотрудников."])},
    "rank_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените, пожалуйста, уровень, где вы согласны со следующими утверждениями."])},
    "survey_sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделы опроса"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать на программу самооценки TOM"])},
    "welcome_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценка TOM — это инструмент, который поможет вам понять ваш текущий уровень компетентности в областях, отслеживаемых в системе компетенций по контролю заболеваний. Каждый раздел рассчитан на 10-15 минут."])},
    "welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам будет предложено оценить уровень уверенности в своих знаниях или способности выполнять задачи, которые отражают различные уровни знаний в рамках 13-и компетенций системы."])},
    "click_survey_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите на любой из разделов опроса на боковой панели, чтобы начать работу."])},
    "thank_you_for_completing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим вас за прохождение самооценки TOM!"])},
    "assessment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представить на рассмотрение"])},
    "section_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты опросов"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью согласен"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не согласен"])},
    "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не применимо"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопросник для самостоятельной оценки: система компетенций в области борьбы с болезнями"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровни"])}
  },
  "routes": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная страница"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["проверять"])},
    "Password Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PasswordReset"])},
    "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "Nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кандидат"])},
    "Request Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос индивидуального курса"])},
    "Training Credit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная система обучения"])},
    "Suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить предложение"])},
    "Future Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущие возможности"])},
    "Single Learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Learner"])},
    "Competency Framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система компетенций"])},
    "Custom Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты пользователя"])},
    "Add User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить данные пользователя"])},
    "Adjust Levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить уровни компетенции"])},
    "admin_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Login"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "Learner Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль учащегося"])}
  },
  "positions": {
    "central_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральный ветеринар"])},
    "regional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Региональный ветеринар"])}
  },
  "regions": {
    "armenia": {
      
    }
  },
  "competencies": {
    "epidemiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эпидемиология"])},
    "tads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансграничные болезни"])},
    "disease_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие  программы  по профилактики и контроля болезней"])},
    "biosecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биобезопасность"])},
    "sampliing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отбор образцов"])},
    "animal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификация животных и контроль за перемещением"])},
    "emergency_disaster_mgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление в условиях чрезвычайных ситуаций и стихийных бедствий"])},
    "emergency_prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовность к чрезвычайным ситуациям"])},
    "emergency_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реагирование на чрезвычайные ситуации "])},
    "vet_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветеринарные продукты"])},
    "animal_welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благополучие животных"])},
    "risk_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применение анализа риска"])},
    "safety_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность, здоровье и благополучие"])}
  },
  "compLevels": {
    "Awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая осведомленность"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новичок"])},
    "Competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетентный"])},
    "Proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессионал"])},
    "Expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эксперт"])}
  }
}