<template>
  <div :class="!toggled ? 'w-screen' : 'flex-grow'">
    <div
      class="text-white bg-green-primary flex justify-between items-center py-3 px-6 z-50"
    >
      <font-awesome-icon
        name="open"
        icon="bars"
        @click.stop="openSideBar"
        class="cursor-pointer text-lg"
        v-if="!toggled && this.$store.state.role !== 2 && auth"
      />

      <div class="container flex justify-between items-center">
        <div class="">
          <img
            class="-ml-3 md:w-40 max-w-full h-auto"
            src="/static/assets/EuFMD_WHITE_logo2.png"
            alt="EuFMD logo"
          />
        </div>
        <router-link
          class="px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/login"
          @click="logOut"
          name="open"
          v-if="auth || adminAuth"
        >
          <div class="flex flex-row items-center">
            <p class="">
              {{ username }}
              <span v-show="this.$store.state.role !== 3"
                >| {{ region }} <span v-show="region"> - </span>
                {{ country }}</span
              >
            </p>
            <font-awesome-icon
              icon="sign-out-alt"
              class="ml-2 cursor-pointer text-lg"
            />
          </div>
        </router-link>
      </div>
    </div>

    <!-- second header -->

    <div class="w-screen py-3 bg-green-tom text-white font-marta">
      <div class="container grid grid-flow-col">
        <div>
          <span class="font-black text-xl mr-3 font-martaBold">Tom</span
          ><span class="font-light mr-2"> EuFMD training management system</span
          ><span class="mr-2">|</span><span class="font-light">Pilot tool</span>
        </div>
        <!-- help button -->
        <!-- <div
          class="py-2 bg-green-tom text-white font-martaBold justify-self-end"
        >
          <div class="ml-4" @click="showHelpModal">
            <font-awesome-icon icon="question-circle" />
            <span class="font-semibold pl-2 underline" href="./">Help</span>
          </div>
        </div> -->
        <div class="justify-self-center">
          <locale-switcher />
        </div>
      </div>
    </div>

    <!-- third header (hidden during login) -->

    <!-- third header (hidden during login) -->

    <div
      v-show="$route.name !== 'Login'"
      class="w-screen bg-white py-3 text-green-primary font-marta"
    >
      <div class="container">
        <p class="font-light ml-14">
          <span class="mr-1">
            <router-link
              v-if="$route.name === 'PasswordReset'"
              class="hover:text-green-secondary hover:underline"
              to="/login"
              >{{ $t("routes.Login") }}</router-link
            >

            <router-link
              v-else
              class="hover:text-green-secondary hover:underline"
              to="/"
              >{{ $t("routes.Home") }}</router-link
            >
          </span>
          <span v-show="route !== 'Home'" class="mr-2"> &gt; {{ $t(`routes.${route}`) }}</span>
        </p>
      </div>
    </div>
    <!-- <HelpModal v-if="showModal" @closeHelpModal="showModal = false" /> -->
  </div>
</template>

<script>
import api from "@/services/data";
//import HelpModal from "../components/HelpModal.vue";
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default {
  // components: { LocaleSwitcher HelpModal},
  components: { LocaleSwitcher },
  name: "Navbar",
  props: ["toggled", "auth", "adminAuth"],
  data() {
    return {
      username: "",
      country: "",
      region: "",
      route: "",
      showModal: false,
    };
  },
  methods: {
    showHelpModal() {
      this.showModal = !this.showModal;
    },
    logOut() {
      // notify parent component (App) of logout
      if (this.$store.state.role === 1 || this.$store.state.role === 2) {
        this.$emit("auth", false);
        this.$emit("closeRegionModal");
      } else if (this.$store.state.role === 3) {
        this.$emit("adminAuth", false);
      }
      api.sendLogoutTime();
      //remove token from local storage
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("auth");
      localStorage.removeItem("adminAuth");
      //localStorage.clear();
    },
    //fetch user data that will be displayed in the navbar
    async getData() {
      let token = localStorage.getItem("token");
      let parsed = JSON.parse(atob(token.split(".")[1]));
      let role = parsed.user_role;

      if (role === 1 || role === 2 || role === 4) {
        const res = await api.getProfile();
        this.username = res.fullname;
        this.country = res.country;
        this.region = res.region;
      }
      else if (role === 3) {
        this.username = "TOM Admin";
        this.country = " ";
      }
    },
    //inform parent of sidebar opening
    openSideBar() {
      this.$emit("sideBarToggled", true);
    },
    //process route name to display it properly
    processRoute(str) {
      return (
        str.charAt(0).toUpperCase() +
        this.$route.name.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2")
      );
    },
  },
  created() {
    (this.auth || this.adminAuth) && this.getData();
  },
  watch: {
    //change route name on breadcrumbs navigation
    $route() {
      let currentRoute = this.$route.name;
      // console.log({ currentRoute });
      this.route =
        currentRoute == "SingleLearner"
          ? this.$t('routes.Learner Profile')
          : this.processRoute(currentRoute);
    },
    auth: function () {
      this.getData();
    },
    adminAuth: function () {
      this.getData();
    },
  },
};
</script>

<style></style>
